import React, {useState, useRef, MutableRefObject, useEffect, useCallback, Dispatch, SetStateAction} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {Box, LinearProgress, Typography} from "@mui/material";
import _ from "lodash";
import {
    DisplaySettingsOutlined,
    SettingsRemoteOutlined,
    SsidChartOutlined,
    SummarizeOutlined,
} from "@mui/icons-material";
import {FieldErrors, FormProvider, useForm, UseFormReturn} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {enqueueSnackbar} from "notistack";
import * as yup from "yup";
import {AxiosResponse} from "axios";
import {ParentProps, ViewMode, DialogType} from "../types";
import {APP_PATHS, FAMILIARITY_LABEL_MAP, ACCESS_LEVEL_LABEL_MAP, COMMUNICATION_PROTOCOLS, BAS_ID, ARCHIVED_REASONS, FORM_REQUIRED_SNACK_MESSAGE} from "../config";
import {Field} from "../components/generics/inputs";
import {useContext} from "../components/generics/Context";
import useCall, {BaseState} from "../components/generics/useCall";
// components
import WithMenu, {MenuItem, resolveActions} from "../components/layout/WithMenu";
import Backdrop from "../components/generics/Backdrop";
import ChangeLog from "../components/layout/ChangeLog";
import {
    alterEditableFieldsViewMode,
    constructBasPostConfig,
    constructBasPatchConfig,
    constructBasArchiveConfig,
    constructBasDeleteConfig,
    cleanValue,
    initBasForm,
    dataFromApi,
} from "../handlers";
import {
    Overview,
    RemoteAccess,
    Trending,
    ControlFunctions,
    OverviewFields,
    RemoteAccessFields,
    TrendingFields,
    ControlFunctionsFields,
    Archive,
    Prompt,
} from "../components/forms";

export interface BasFields{
    [index:string]:any
    overview: Field[],
    remoteAccess: Field[],
    trending: Field[],
    controlFunctions: Field[],
}

interface Props extends ParentProps{
    // NOTE: Empty For future expansion.
}

export interface State extends BaseState{
    viewMode:ViewMode
}

/**
 * BasDetails
 * view is for PATCH | POST verbs. check params! look for basId presence
 * @param {Props} props
 * @return {React.ReactElement}
 */
function BasDetails(props:Props):React.ReactElement {
    const params:any=useParams();
    const navigate=useNavigate();
    const location=useLocation();
    const context:any=useContext();
    const [state, setState]:[State, Dispatch<SetStateAction<State>>]=useState<State>({
        dialog: "NONE",
        viewMode: params.basId?"VIEW_MODE":"POST_MODE",
        record: null,
        formValues: null,
    });
    const [formFields, setFormFields]=useState<BasFields>({
        overview: OverviewFields,
        remoteAccess: RemoteAccessFields,
        trending: TrendingFields,
        controlFunctions: ControlFunctionsFields,
    });
    const [controllingFields, setControllingFields]:any=useState({
        remote_access_capability: {status: false, keys: RemoteAccessFields.map((f:Field) => f.key)},
        trending_view_charts_as_graph: {status: false, keys: TrendingFields.slice(0, 5).map((f:Field) => f.key)},
        trending_charts_create_or_modify: {status: false, keys: TrendingFields.slice(5, 10).map((f:Field) => f.key)},
        trending_points_can_enable_new: {status: false, keys: TrendingFields.slice(10, TrendingFields.length).map((f:Field) => f.key), skipKeys: ["can_download_trend_chart_csv"]},
    });
    const {call, get}=useCall({state, setState: (setState as any), redirectUrl: APP_PATHS.BAS.replace(/:propertyId/g, props.property.data.id)});
    const {current: fields}:MutableRefObject<BasFields>=useRef(formFields);
    const {current: mutableControllingFields}:MutableRefObject<any>=useRef(controllingFields);

    // construct yup object
    const yupObject:any={};
    Object.keys(fields).forEach((key:string) => fields[key].forEach((field:Field) => {
        // if (SOFTWARE_VERSION_KEYS.includes(field.key)) yupObject[field.key] = field.yup.test(testSoftwareVersion(params, state.record));
        yupObject[field.key] = field.yup;
    }));

    const formReturn:UseFormReturn=useForm({
        mode: "onSubmit",
        resolver: yupResolver(yup.object(yupObject)),
    });

    /**
     * onDialogClick
     * @param {DialogType} type
     * @return {void}
     */
    const onDialogClick=(type:DialogType) => (args:React.MouseEvent):void => setState({..._.cloneDeep(state), dialog: type});

    /**
     * onPostSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onPostSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar("Building Automation System saved", {variant: "success"});
        // trigger refresh cycle
        formReturn.reset(initBasForm());
        const stateClone=_.cloneDeep(state);
        stateClone.formValues=null;
        stateClone.viewMode="VIEW_MODE";
        setState(stateClone);
        context.setViewState("REFRESH");
        // redirect
        navigate(APP_PATHS.BAS_DETAILS.replace(/:basId/g, res.data.id).replace(/:propertyId/g, props.property.data.id));
    }, [formReturn, context, navigate, props.property.data.id, state]);

    /**
     * onPatchSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onPatchSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar("Building Automation System saved", {variant: "success"});
        // trigger refresh cycle
        formReturn.reset(initBasForm());
        const stateClone=_.cloneDeep(state);
        stateClone.formValues=null;
        stateClone.viewMode="VIEW_MODE";
        stateClone.record=null;
        setState(stateClone);
        context.setViewState("REFRESH");
    }, [formReturn, context, state]);

    /**
     * onArchiveSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onArchiveSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${state.record?.data.software} BAS archived`, {variant: "success"});
        // trigger refresh cycle
        formReturn.reset(initBasForm());
        const stateClone=_.cloneDeep(state);
        stateClone.dialog="NONE";
        stateClone.formValues=null;
        stateClone.record=null;
        stateClone.viewMode="VIEW_MODE";
        setState(stateClone);
        context.setViewState("REFRESH");
        // redirect
        navigate(APP_PATHS.BAS.replace(/:propertyId/g, props.property.data.id));
    }, [formReturn, context, props.property.data.id, state, navigate]);

    /**
     * onRestoreSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onRestoreSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${state.record?.data.software} BAS restored`, {variant: "success"});
        // trigger refresh cycle
        formReturn.reset(initBasForm());
        const stateClone=_.cloneDeep(state);
        stateClone.dialog="NONE";
        stateClone.formValues=null;
        stateClone.record=null;
        stateClone.viewMode="VIEW_MODE";
        setState(stateClone);
        context.setViewState("REFRESH");
    }, [formReturn, context, state]);

    /**
     * onDeleteSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onDeleteSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${state.record?.data.software} BAS Deleted`, {variant: "success"});
        // trigger refresh cycle
        const stateClone=_.cloneDeep(state);
        stateClone.dialog="NONE";
        stateClone.formValues=null;
        stateClone.record=null;
        stateClone.viewMode="VIEW_MODE";
        setState(stateClone);
        context.setViewState("REFRESH");
        // redirect
        navigate(APP_PATHS.BAS.replace(/:propertyId/g, props.property.data.id));
    }, [context, props.property.data.id, state, navigate]);

    /**
     * onArchiveSubmit
     * @param {any} values
     * @return {void}
     */
    const onArchiveSubmit= async (values:any):Promise<void> => {
        try {
            call(constructBasArchiveConfig(values, props.property, state.record as AxiosResponse, true), values, onArchiveSuccess, "archive");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onRestoreSubmit
     * @param {any} values
     * @return {void}
     */
    const onRestoreSubmit= async (values:any):Promise<void> => {
        try {
            call(constructBasArchiveConfig(null, props.property, state.record as AxiosResponse, false), null, onRestoreSuccess, "restore");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onDeleteSubmit
     * @param {any} values
     * @return {void}
     */
    const onDeleteSubmit= async (values:any):Promise<void> => {
        try {
            call(constructBasDeleteConfig(state.record as AxiosResponse), null, onDeleteSuccess, "delete");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onPost
     * @param {any} values
     */
    const onPost=async (values:any):Promise<void> => {
        try {
            call(constructBasPostConfig(values, props.property), values, onPostSuccess, "post");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onUpdate
     * @param {any} values
     */
    const onUpdate=async (values:any):Promise<void> => {
        try {
            call(constructBasPatchConfig(values, props.property, state.record as AxiosResponse), values, onPatchSuccess, "edit");
        } catch (e) {
            console.log(e);
        }
    };

    // 412 handling
    useEffect(() => {
        if (context.viewState==="PENDING_END" && state.record && state.formValues) {
            if ("edit" in state.formValues) call(constructBasPatchConfig(state.formValues.edit, props.property, state.record), state.formValues.edit, onPatchSuccess, "edit");
            else if ("archive" in state.formValues) call(constructBasArchiveConfig(state.formValues.archive, props.property, state.record, true), state.formValues.archive, onArchiveSuccess, "archive");
            else if ("restore" in state.formValues) call(constructBasArchiveConfig(state.formValues.restore, props.property, state.record, false), state.formValues.restore, onRestoreSuccess, "restore");
            else if ("delete" in state.formValues) call(constructBasDeleteConfig(state.record as AxiosResponse), null, onDeleteSuccess, "delete");
        }
    }, [context.viewState, call, props.property, state.formValues, state.record, onPatchSuccess, onArchiveSuccess, onRestoreSuccess, onDeleteSuccess]);

    useEffect(() => {
        if (params.basId && state.record===null) get(`${BAS_ID.replace(/{id}/g, params.basId)}`, "record");
        if (!("basId" in params) && location.state?.record && state.record===null) setState({..._.cloneDeep(state), record: location.state.record});
    }, [params, state.record, get, location.state?.record, state]);

    // alter editable fields via viewMode
    useEffect(() => {
        setFormFields(alterEditableFieldsViewMode(fields, state.viewMode));
    }, [state.viewMode, fields, params.basId]);

    // populate form values
    useEffect(() => {
        if (state.record===null) return;
        const values={
            ...OverviewFields.reduce(
                (a:any, v:any) => {
                    let cv:any=cleanValue(state.record?.data?.[v.key]);
                    if (v.key==="communication_protocols") {
                        cv=COMMUNICATION_PROTOCOLS
                            .filter((comm:any) => (state.record?.data && (comm.key in state.record.data) && state.record?.data[comm.key]===true))
                            .map((comm:any) => comm.label);
                    } else if (v.key==="familiarity_with_bas" || v.key==="familiarity_with_trending") cv=cleanValue(FAMILIARITY_LABEL_MAP.find((i:any) => i.key===cv)?.label);
                    else if (a.type==="toggle") cv=dataFromApi(cv);
                    return ({
                        ...a,
                        [v.key]: cv,
                    });
                },
                {},
            ),
            ...RemoteAccessFields.reduce(
                (a:any, v:any) => {
                    let cv:any=cleanValue(state.record?.data?.[v.key]);
                    if (v.key==="level_of_access") cv=cleanValue(ACCESS_LEVEL_LABEL_MAP.find((i:any) => i.key===cv)?.label);
                    else if (a.type==="toggle") cv=dataFromApi(cv);
                    return ({
                        ...a,
                        [v.key]: cv,
                    });
                },
                {},
            ),
            ...TrendingFields.reduce(
                (a:any, v:any) => {
                    let cv:any=cleanValue(state.record?.data?.[v.key]);
                    if (a.type==="toggle") cv=dataFromApi(cv);
                    return ({
                        ...a,
                        [v.key]: cv,
                    });
                },
                {},
            ),
            ...ControlFunctionsFields.reduce(
                (a:any, v:any) => {
                    let cv:any=cleanValue(state.record?.data?.[v.key]);
                    if (a.type==="toggle") cv=dataFromApi(cv);
                    return ({
                        ...a,
                        [v.key]: cv,
                    });
                },
                {},
            ),
        };
        if (context.viewState==="NONE") {
            formReturn.reset(values);
            // resolve controlling fields
            setControllingFields({...Object.keys(mutableControllingFields).reduce((a:any, v:any) => ({...a, [v]: {...mutableControllingFields[v], status: state.record?.data?.[v]}}), {})});
        }
    }, [formReturn, context.viewState, state.record, mutableControllingFields]);

    useEffect(() => {
        const subscription = formReturn.watch(_.debounce((values:any, {name, type}:any) => {
            // cloning controlling fields
            const clone:any=_.cloneDeep(controllingFields);
            if (Object.keys(clone).includes(name)) {
                // changing status of the parent controlling key
                clone[name].status=values[name];
                // NOTE: reseting needed to maintain form business logic
                // reseting children keys of that belong to controlling key
                clone[name].keys.slice(0, clone[name].keys.length-1).forEach((key:string) => {
                    // resolve field out of form fields
                    const field:Field=Object.keys(fields).map((k:string) => fields[k].find((f:Field) => f.key===key)).find((i:any) => i);
                    // map default field type values
                    const DEFAULT_TYPE_VALUE:any={switch: false, textfield: "", autocomplete: null};
                    // setting default values
                    if (key!==name && (clone[name].skipKeys?.includes(key)===false || clone[name].skipKeys===undefined)) formReturn.setValue(key, DEFAULT_TYPE_VALUE[field.type]);
                });
                setControllingFields(clone);
            }
            // software version UI error handling (CLIENT-SIDE composite-key validation)
            // if (SOFTWARE_VERSION_KEYS.includes(name)) {
            //     SOFTWARE_VERSION_KEYS.forEach((k:string) => { if (values[k] || k===name) formReturn.trigger(k); });
            // }
        }, 0));
        return () => subscription.unsubscribe();
    }, [formReturn, fields, controllingFields]);

    // menu content
    const menu:MenuItem[]=[
        {
            key: "overview",
            label: "Overview",
            header: {
                actions: resolveActions(
                    state.viewMode,
                    state.record?.data.archived,
                    {
                        edit: {onClick: (args:React.MouseEvent) => setState({..._.cloneDeep(state), viewMode: "EDIT_MODE"})},
                        archive: {onClick: onDialogClick("BAS_ARCHIVE")},
                        restore: {onClick: onDialogClick("BAS_RESTORE")},
                        // delete: {onClick: onDialogClick("BAS_DELETE")},
                        discard: {onClick: (args:React.MouseEvent) => setState({..._.cloneDeep(state), viewMode: "VIEW_MODE"})},
                        update: {onClick: onUpdate, onError: (args:FieldErrors) => enqueueSnackbar(FORM_REQUIRED_SNACK_MESSAGE, {variant: "error"})},
                        cancel: {onClick: (args:React.MouseEvent) => navigate(APP_PATHS.BAS.replace(/:propertyId/g, props.property.data.id), {state: {isPostCanceled: true}})},
                        post: {onClick: onPost, onError: (args:FieldErrors) => enqueueSnackbar(FORM_REQUIRED_SNACK_MESSAGE, {variant: "error"})},
                    },
                ),
                primaryLabel: state.record===null?"New System":state.record?.data?.software,
                secondaryLabel: "Building Automation System",
                archived: state.record?.data?.archived,
                rootSticky: true,
                viewMode: state.viewMode,
            },
            subHeader: {
                label: "Overview",
            },
            icon: SummarizeOutlined,
            content: <Overview fields={formFields.overview} familiarity={FAMILIARITY_LABEL_MAP.map((i:any) => i.label)} communicationProtocols={COMMUNICATION_PROTOCOLS.map((i:any) => i.label)} />,
        },
        {
            key: "remote-access",
            label: "Remote Access",
            subHeader: {
                label: "Remote Access",
            },
            icon: SettingsRemoteOutlined,
            content: <RemoteAccess fields={formFields.remoteAccess} controllingFields={controllingFields} accessLevel={ACCESS_LEVEL_LABEL_MAP.map((i:any) => i.label)} />,
        },
        {
            key: "trending",
            label: "Trending",
            subHeader: {
                label: "Trending",
            },
            icon: SsidChartOutlined,
            content: <Trending fields={formFields.trending} controllingFields={controllingFields} />,
        },
        {
            key: "control-functions",
            label: "Control Functions",
            subHeader: {
                label: "Control Functions",
            },
            icon: DisplaySettingsOutlined,
            content: <ControlFunctions fields={formFields.controlFunctions} />,
        },
    ];

    if (params.basId && (state.record===null || formReturn.formState.defaultValues===undefined)) return <LinearProgress />;

    return (
        <Box>
            {/* archive dialog */}
            {context.viewState==="NONE" && state.record?.data && (
                <Archive
                    status={state.dialog}
                    dialogType="BAS_ARCHIVE"
                    onClose={onDialogClick("NONE")}
                    onSubmit={onArchiveSubmit}
                    title={state.record?.data.software}
                    message={(
                        <Box>
                            <Typography variant="body1">
                                Archived BAS will not be included in exports or reports for this property. The BAS association with equipment is maintained.
                            </Typography>
                            <Typography variant="body1">Archived BAS can be restored.</Typography>
                        </Box>
                    )}
                    reasons={ARCHIVED_REASONS}
                />
            )}
            {/* restore dialog */}
            {context.viewState==="NONE" && state.record?.data && (
                <Prompt
                    status={state.dialog}
                    dialogType="BAS_RESTORE"
                    onClose={onDialogClick("NONE")}
                    onSubmit={{onClick: onRestoreSubmit, label: "Restore"}}
                    title={`Restore ${state.record?.data.software}`}
                    message={(<Typography variant="body1">Restored BAS will be included in exports or reports for this property.</Typography>)}
                />
            )}
            {/* delete dialog */}
            {context.viewState==="NONE" && state.record?.data && (
                <Prompt
                    status={state.dialog}
                    dialogType="BAS_DELETE"
                    onClose={onDialogClick("NONE")}
                    onSubmit={{onClick: onDeleteSubmit, label: "Delete"}}
                    title={`Delete ${state.record?.data.software}`}
                    message={(<Typography variant="body1">This action cannot be undone.</Typography>)}
                />
            )}
            {/* lock */}
            <Backdrop open={!["NONE"].includes(context.viewState)} />
            {/* content */}
            {context.viewState==="NONE" && (
                <FormProvider {...formReturn}>
                    <WithMenu
                        menu={menu}
                        log={state.viewMode==="POST_MODE" || !state.record
                            ?undefined
                            :<ChangeLog record={state.record} resource="bas" />}
                    />
                </FormProvider>
            )}
        </Box>
    );
}

export default BasDetails;
