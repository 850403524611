import {AxiosRequestConfig, AxiosResponse} from "axios";
import {FieldValues} from "react-hook-form";
import _ from "lodash";
import {COMMUNICATION_PROTOCOLS, BAS_ID, FAMILIARITY_LABEL_MAP, ACCESS_LEVEL_LABEL_MAP} from "../config";
import {OverviewFields, RemoteAccessFields, TrendingFields, ControlFunctionsFields} from "../components/forms";
import {dataToApi, resolveLabel, resolveLabels} from ".";

/**
 * initBasForm
 * @param {any} values
 * @return {any}
 */
const initBasForm=(values?:any):any => ({
    ...OverviewFields.reduce((a:any, v:any) => ({...a, [v.key]: values?values[v.key]:undefined}), {}),
    ...RemoteAccessFields.reduce((a:any, v:any) => ({...a, [v.key]: values?values[v.key]:undefined}), {}),
    ...TrendingFields.reduce((a:any, v:any) => ({...a, [v.key]: values?values[v.key]:undefined}), {}),
    ...ControlFunctionsFields.reduce((a:any, v:any) => ({...a, [v.key]: values?values[v.key]:undefined}), {}),
});

/**
 * resolvePayload
 * @param {any} values
 * @return {any}
 */
const resolvePayload=(values:any):any => {
    // remove communication_protocols
    const payload=_.omit(values, "communication_protocols");
    // resolve familiarity_with_bas
    payload.familiarity_with_bas=resolveLabel(FAMILIARITY_LABEL_MAP, values.familiarity_with_bas);
    // resolve familiarity_with_trending
    payload.familiarity_with_trending=resolveLabel(FAMILIARITY_LABEL_MAP, values.familiarity_with_trending);
    // resolve level_of_access
    payload.level_of_access=resolveLabel(ACCESS_LEVEL_LABEL_MAP, values.level_of_access);
    const fields = [...OverviewFields, ...RemoteAccessFields, ...TrendingFields, ...ControlFunctionsFields];
    const transformedPayload = fields.reduce((a:any, v:any) => ({...a, [v.key]: v.type==="toggle"?dataToApi(payload[v.key]):payload[v.key]}), {});
    // resolve communication_protocols
    resolveLabels(values.communication_protocols, transformedPayload, COMMUNICATION_PROTOCOLS);
    return transformedPayload;
};

/**
 * constructBasPostConfig
 * @param {FieldValues|null} values
 * @param {AxiosResponse} property
 * @return {AxiosRequestConfig|undefined}
 */
const constructBasPostConfig=(values:FieldValues|null, property:AxiosResponse):AxiosRequestConfig|undefined => {
    if (values===null) return undefined;

    const payload=resolvePayload(values);

    return {
        method: "post",
        url: `${BAS_ID.replace(/\/{id}/g, "")}/`,
        data: {...payload, property_id: property.data.parent_property_id||property.data.id},
    };
};

/**
 * constructBasDuplicateConfig
 * @param {any} row
 * @param {AxiosResponse} property
 * @return {AxiosRequestConfig|undefined}
 */
const constructBasDuplicateConfig=(row:any, property:AxiosResponse):AxiosRequestConfig|undefined => {
    if (row===null) return undefined;

    let payload:any=initBasForm(row);
    // remove communication_protocols
    payload=_.omit(payload, "communication_protocols");
    // resolve communication_protocols
    COMMUNICATION_PROTOCOLS.forEach((comm:any) => { payload[comm.key]=row[comm.key]; });

    return {
        method: "post",
        url: `${BAS_ID.replace(/\/{id}/g, "")}/`,
        data: {
            property_id: property.data.parent_property_id||property.data.id,
            ...payload,
        },
    };
};

/**
 * constructBasPatchConfig
 * @param {FieldValues|null} values
 * @param {AxiosResponse} property
 * @param {AxiosResponse} bas
 * @return {AxiosRequestConfig|undefined}
 */
const constructBasPatchConfig=(values:FieldValues|null, property:AxiosResponse, bas:AxiosResponse):AxiosRequestConfig|undefined => {
    if (values===null) return undefined;

    const payload=resolvePayload(values);

    return {
        method: "patch",
        url: `${BAS_ID.replace(/{id}/g, bas.data.id)}`,
        data: {...payload, property_id: property.data.parent_property_id||property.data.id},
        headers: {"If-Match": bas.data.etag_version}, // eslint-disable-line camelcase
    };
};

/**
 * constructBasArchiveConfig
 * @param {FieldValues|null} values
 * @param {AxiosResponse} property
 * @param {AxiosResponse} bas
 * @param {boolean} archived
 * @return {AxiosRequestConfig|undefined}
 */
const constructBasArchiveConfig=(values:FieldValues|null, property:AxiosResponse, bas:AxiosResponse, archived:boolean):AxiosRequestConfig|undefined => {
    if (values===null && archived) return undefined;

    return {
        method: "patch",
        url: `${BAS_ID.replace(/{id}/g, bas.data.id)}`,
        data: {
            property_id: property.data.parent_property_id||property.data.id,
            archived_reason: archived?values?.archived_reason:"",
            archived,
            software: bas.data.software,
            version: bas.data.version,
        },
        headers: {"If-Match": bas.data.etag_version}, // eslint-disable-line camelcase
    };
};

/**
 * constructBasDeleteConfig
 * @param {AxiosResponse} bas
 * @return {AxiosRequestConfig|undefined}
 */
const constructBasDeleteConfig=(bas:AxiosResponse):AxiosRequestConfig|undefined => ({
    method: "delete",
    url: `${BAS_ID.replace(/{id}/g, bas.data.id)}`,
    headers: {"If-Match": bas.data.etag_version}, // eslint-disable-line camelcase
});

export {
    constructBasPostConfig,
    constructBasPatchConfig,
    constructBasArchiveConfig,
    constructBasDeleteConfig,
    constructBasDuplicateConfig,
    initBasForm,
};
