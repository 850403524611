import * as yup from "yup";
import {API_PRIMARY_FIELD_REGEX} from "../../../config";
import {Field} from "../../generics/inputs";

export const COMMON_FIELDS:{[key: string]: Field} = {
    name: {
        key: "name",
        label: "System Name",
        yup: yup.string().matches(API_PRIMARY_FIELD_REGEX.regex, {message: API_PRIMARY_FIELD_REGEX.message}).required("Required"),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
    },
    tag_id: {
        key: "tag_id",
        label: "System ID/Tag",
        yup: yup.string().matches(API_PRIMARY_FIELD_REGEX.regex, {message: API_PRIMARY_FIELD_REGEX.message}).required("Required"),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
    },
    bas_version: {
        key: "bas_version",
        label: "Building Automation System",
        yup: yup.mixed().required("Required"),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            readOnly: true,
        },
    },
    operation_times: {
        key: "operation_times",
        label: "Operation Times",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            readOnly: true,
            multiple: true,
        },
    },
    sequence_of_operations: {
        key: "sequence_of_operations",
        label: "Sequence of Operations",
        yup: yup.string().url("Invalid url."),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
    },
    notes: {
        key: "notes",
        label: "Notes",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            maxLength: 800,
            textArea: {
                multiline: true,
                rows: 10,
            },
        },
    },
};
