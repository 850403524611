import React from "react";
import {Grid} from "@mui/material";
import * as yup from "yup";
import {useFormContext} from "react-hook-form";
import {populateValues} from "../../../handlers";
import {MIN_DATE, MAX_DATE, API_PRIMARY_FIELD_REGEX} from "../../../config";
import {ControlledAutocomplete, ControlledTextField, ControlledToggleButton, Field} from "../../generics/inputs";

interface OverviewProps{
    fields:Field[]
    familiarity:string[]
    communicationProtocols:string[]
}

// const SOFTWARE_VERSION_TEST_MESSAGE="Duplicate software version";
// export const SOFTWARE_VERSION_KEYS=["software", "version"];

// /**
//  * validateSoftwareVersion (CLIENT-SIDE composite-key validation)
//  * @param {any} params
//  * @param {any} values
//  * @param {AxiosResponse|null} record
//  * @return {Promise<boolean>}
//  */
// const validateSoftwareVersion=async (params:any, values:any, record:AxiosResponse|null):Promise<boolean> => {
//     if (!values.software || !values.version) return false;
//     const res:AxiosResponse=await get(`${PROPERTY_ID_BAS.replace(/{id}/g, params.propertyId)}?software=${values.software}`);
//     let errors;
//     if (res.status===200 && (Array.isArray(res.data?.results)) && res.data.results.length>0) {
//         errors=res.data.results
//             .map((item:any) => (values.version===item.version && record?.data.id!==item.id))
//             .filter((item:any) => item===true);
//     }
//     return errors?.length>0;
// };

// /**
//  * testSoftwareVersion
//  * @param {any} params
//  * @param {AxiosResponse|null} record
//  * @return {Promise<boolean|yup.ValidationError>}
//  */
// export const testSoftwareVersion=(params:any, record:AxiosResponse|null) => async (v:string, c:yup.TestContext):Promise<boolean|yup.ValidationError> => {
//     const test=await validateSoftwareVersion(params, c.parent, record);
//     const errors:yup.ValidationError[]=[];
//     if (test) {
//         SOFTWARE_VERSION_KEYS.forEach((k:string) => { errors.push(new yup.ValidationError(SOFTWARE_VERSION_TEST_MESSAGE, "", k)); });
//         return c.createError({message: () => errors});
//     }
//     return true;
// };

export const Fields:Field[] = [
    {
        key: "software",
        label: "Software",
        yup: yup.string().matches(API_PRIMARY_FIELD_REGEX.regex, {message: API_PRIMARY_FIELD_REGEX.message}).required(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
        exportOptions: {
            label: "BAS_Software",
            order: 2,
        },
    },
    {
        key: "year_installed",
        label: "Year BAS Installed",
        yup: yup
            .number()
            .typeError("Invalid Year")
            .test("year-test", "Invalid Year", (val:any) => {
                if (val===null) return true;
                return (val>=MIN_DATE.year() && val<=MAX_DATE.year());
            })
            .nullable()
            .transform((_, val) => (val!==""?Number(val):null)),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
        exportOptions: {
            label: "BAS_Year Installed",
            order: 4,
        },
    },
    {
        key: "manufacturer",
        label: "Manufacturer",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
        exportOptions: {
            label: "BAS_Manufacturer",
            order: 1,
        },
    },
    {
        key: "bas_service_contract",
        label: "BAS service contract?",
        yup: yup.mixed().nullable(),
        type: "toggle",
        toggleOptions: {
            label: true,
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Service Contract?",
            order: 5,
        },
    },
    {
        key: "version",
        label: "Version",
        yup: yup.string().matches(API_PRIMARY_FIELD_REGEX.regex, {message: API_PRIMARY_FIELD_REGEX.message}).required(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
        },
        exportOptions: {
            label: "BAS_Version",
            order: 3,
        },
    },
    {
        key: "familiarity_with_bas",
        label: "Operator Familiarity with BAS",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            readOnly: true,
        },
        exportOptions: {
            label: "BAS_Familiarity With BAS",
            order: 6,
        },
    },
    {
        key: "trending_access",
        label: "Trending Access",
        yup: yup.mixed().nullable(),
        type: "toggle",
        toggleOptions: {
            label: true,
            disabled: true,
        },
        exportOptions: {
            label: "BAS_Trending Access",
            order: 7,
        },
    },
    {
        key: "familiarity_with_trending",
        label: "Operator Familiarity with Trending",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            readOnly: true,
        },
        exportOptions: {
            label: "BAS_Familiarity With Trending",
            order: 9,
        },
    },
    {
        key: "communication_protocols",
        label: "Communication Protocols",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            readOnly: true,
            multiple: true,
        },
        exportOptions: {
            label: "BAS_Communication Protocols",
            order: 8,
        },
    },

];

/**
 * Overview
 * @param {OverviewProps} props
 * @return {React.ReactElement}
 */
function Overview(props:OverviewProps):React.ReactElement {
    const methods=useFormContext();
    // set field options
    populateValues(props.familiarity, props.fields, "familiarity_with_bas");
    populateValues(props.familiarity, props.fields, "familiarity_with_trending");
    populateValues(props.communicationProtocols, props.fields, "communication_protocols");

    return (
        <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="flex-start">
            {props.fields.slice(0, props.fields.length-1).map((field:Field) => (
                <Grid key={field.key} item xs={12} sm={12} md={12} lg={6} xl={6}>
                    {field.type==="textfield" && <ControlledTextField field={field} control={methods.control} errors={methods.formState.errors} />}
                    {field.type==="toggle" && <ControlledToggleButton field={field} control={methods.control} />}
                    {field.type==="autocomplete" && <ControlledAutocomplete field={field} control={methods.control} errors={methods.formState.errors} />}
                </Grid>
            ))}
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <ControlledAutocomplete field={props.fields[props.fields.length-1]} control={methods.control} />
            </Grid>
        </Grid>
    );
}

export default Overview;
