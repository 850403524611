import React, {useState, useRef, MutableRefObject, useEffect, useCallback, Dispatch, SetStateAction} from "react";
import {useParams, useNavigate, useLocation, Location} from "react-router-dom";
import {Box, Divider, LinearProgress, Typography} from "@mui/material";
import _ from "lodash";
import {
    HeatPumpOutlined,
    GridViewOutlined,
    AddOutlined,
    ArchiveOutlined,
} from "@mui/icons-material";
import {useForm, UseFormReturn, FieldErrors, FieldValues} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {enqueueSnackbar} from "notistack";
import * as yup from "yup";
import {AxiosResponse} from "axios";
import {ParentProps, ViewMode, DialogType, EquipmentType, Component, VariantType, OptionType} from "../types";
import {APP_PATHS, BASE_ROUTE, EQUIPMENT_BASE, PROPERTY_ID_BAS, ARCHIVED_REASONS, FORM_REQUIRED_SNACK_MESSAGE, BAS_ARCHIVED_LABEL} from "../config";
import {Field} from "../components/generics/inputs";
import {useContext} from "../components/generics/Context";
import useCall, {BaseState} from "../components/generics/useCall";
// components
import WithMenu, {MenuItem, resolveActions, SubMenuItem, scrollTo} from "../components/layout/WithMenu";
import AddComponentFilter from "../components/forms/filters/AddComponentFilter";
import EmptyStateBlock from "../components/generics/EmptyStateBlock";
import Backdrop from "../components/generics/Backdrop";
import ChangeLog, {Resource} from "../components/layout/ChangeLog";
import {
    alterEditableFieldsViewMode,
    initSystemForm,
    constructSystemPostConfig,
    constructSystemPatchConfig,
    constructSystemArchiveConfig,
    constructSystemDeleteConfig,
    initComponentForm,
    constructComponentPostConfig,
    constructComponentPatchConfig,
    constructComponentArchiveConfig,
    constructComponentDeleteConfig,
} from "../handlers";
import {
    Archive,
    Prompt,
    EQUIPMENTS,
} from "../components/forms";

export interface EquipmentFields{
    [index:string]:any
    systemFields:Field[]
}

interface Props extends ParentProps{
    // NOTE: Empty For future expansion.
}

interface WithLogProps{
    component:React.ReactElement
    record:AxiosResponse|null
    resource:Resource
    bases:AxiosResponse|null
}

export interface State extends BaseState{
    viewMode:ViewMode
    bases:AxiosResponse|null
}

export interface ComponentState extends Omit<BaseState, "dialog">{
    viewMode:ViewMode
    components:AxiosResponse|null
    component:Component|null
    recordUrl:string|null
}

const COMPONENT_POST_KEY="component-post";

/**
 * WithLog
 * @param {WithLogProps} props
 * @return {React.ReactElement}
 */
function WithLog(props:WithLogProps):React.ReactElement {
    return (
        <Box>
            {props.component}
            <Divider />
            <ChangeLog bases={props.bases} resource={props.resource} record={props.record} />
        </Box>
    );
}

/**
 * EquipmentDetails
 * view is for PATCH | POST verbs. check params! look for equipmentId presence
 * @param {Props} props
 * @return {React.ReactElement}
 */
function EquipmentDetails(props:Props):React.ReactElement {
    const params:any=useParams();
    const location:Location=useLocation();
    const navigate=useNavigate();
    const context:any=useContext();
    const [state, setState]:[State, Dispatch<SetStateAction<State>>]=useState<State>({
        dialog: "NONE",
        viewMode: params.equipmentId?"VIEW_MODE":"POST_MODE",
        record: null,
        formValues: null,
        bases: null,
    });

    const [cState, setCState]:[ComponentState, Dispatch<SetStateAction<ComponentState>>]=useState<ComponentState>({
        viewMode: "VIEW_MODE",
        record: null,
        recordUrl: null,
        formValues: null,
        components: null,
        component: null,
    });

    const equipment:EquipmentType=EQUIPMENTS.find((e:EquipmentType) => e.key===params.systemId && (location.state?.record?.variant?(location.state?.record?.variant===e?.variant):true)) as EquipmentType;

    const componentCall=useCall({
        state: cState,
        setState: (setCState as any),
        redirectUrl: (
            `${APP_PATHS.EQUIPMENT_DETAILS
                .replace(/:propertyId/g, props.property.data.id)
                .replace(/:systemId/g, equipment.key)
                .replace(/:equipmentId/g, params.equipmentId)}#${cState.viewMode==="POST_MODE"?COMPONENT_POST_KEY:cState.record?.data.id}`
        ),
    });
    const systemCall=useCall({state, setState: (setState as any), redirectUrl: APP_PATHS.EQUIPMENT.replace(/:propertyId/g, props.property.data.id)});

    const [formFields, setFormFields]=useState<EquipmentFields>({systemFields: equipment.systemFields});
    const {current: fields}:MutableRefObject<EquipmentFields>=useRef(formFields);

    // construct yup object for system and components
    const systemYup:any={};
    const componentYup:any={};
    Object.keys(fields).forEach((key:string) => fields[key].forEach((field:Field) => { systemYup[field.key] = field.yup; }));
    cState.component?.fields?.forEach((field:Field) => { componentYup[field.key] = field.yup; });

    const systemForm:UseFormReturn=useForm({mode: "onSubmit", resolver: yupResolver(yup.object(systemYup))});
    const componentForm:UseFormReturn=useForm({mode: "onSubmit", resolver: yupResolver(yup.object(componentYup))});

    /**
     * resetCState
     */
    const resetCState=useCallback(() => {
        const cStateClone=_.cloneDeep(cState);
        const stateClone=_.cloneDeep(state);
        stateClone.dialog="NONE";
        cStateClone.formValues=null;
        cStateClone.viewMode="VIEW_MODE";
        cStateClone.component=null;
        cStateClone.components=null;
        cStateClone.record=null;
        cStateClone.recordUrl=null;
        setCState(cStateClone);
        setState(stateClone);
    }, [cState, state]);

    /**
     * resetState
     */
    const resetState=useCallback(() => {
        const stateClone=_.cloneDeep(state);
        stateClone.dialog="NONE";
        stateClone.formValues=null;
        stateClone.record=null;
        stateClone.viewMode="VIEW_MODE";
        setState(stateClone);
    }, [state]);

    useEffect(() => {
        // resolve bases
        if (state.bases===null) systemCall.get(`${PROPERTY_ID_BAS.replace(/{id}/g, props.property.data.parent_property_id||props.property.data.id)}`, "bases", true);
        // resolve components
        if (cState.components===null && state.record && state.record.status===200 && equipment.component) {
            componentCall.get(`${BASE_ROUTE}${state.record.data.links[Array.isArray(equipment.component) ? equipment.component[0].key: equipment.component.key]}`, "components", true);
        }
        // resolve record via location (duplicate) OR api call
        if (!("equipmentId" in params) && location.state?.record && location.state.action==="duplicate" && state.record===null) {
            setState({..._.cloneDeep(state), record: {data: _.omit(location.state.record, ["name", "tag_id", "id", "etag_version", "created_on", "created_by_email"])} as AxiosResponse});
        } else if (params.equipmentId && state.record===null) systemCall.get(`${EQUIPMENT_BASE}${equipment.category}/${equipment.key}/${params.equipmentId}`, "record");
        // resolve component record
        if (cState.recordUrl && cState.record===null) componentCall.get(`${BASE_ROUTE}${cState.recordUrl}`, "record");
        if (cState.record?.status===410) resetCState();
    }, [componentCall, resetCState, cState, params, location.state, state, systemCall, props.property.data, equipment]);

    // alter editable fields via viewMode
    useEffect(() => {
        setFormFields(alterEditableFieldsViewMode(fields, state.viewMode));
    }, [state.viewMode, fields]);

    useEffect(() => {
        if (state.record===null || state.bases===null) return;
        const values=equipment.resolveValues.fromApi(state.record, state.bases, equipment.systemFields);
        if (context.viewState==="NONE") systemForm.reset(values);
    }, [context.viewState, state.record, systemForm, state.bases, equipment]);

    /**
     * onDialogClick
     * @param {DialogType} type
     * @return {void}
     */
    const onDialogClick=useCallback((type:DialogType) => (args:React.MouseEvent):void => setState({..._.cloneDeep(state), dialog: type}), [state]);

    /**
     * onAddComponentSubmit
     * @param {Component} component
     * @return {void}
     */
    const onAddComponentSubmit=(component:Component) => (args:React.MouseEvent):void => {
        componentForm.reset(initComponentForm(component.fields));
        setCState({..._.cloneDeep(cState), component, viewMode: "POST_MODE"});
        setState({..._.cloneDeep(state), dialog: "NONE"});
        scrollTo(COMPONENT_POST_KEY, "top");
    };

    /**
     * onSystemPostSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onSystemPostSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar("System saved", {variant: "success"});
        // trigger refresh cycle
        systemForm.reset(initSystemForm(equipment.systemFields));
        resetState();
        context.setViewState("REFRESH");
        // redirect
        navigate(
            APP_PATHS.EQUIPMENT_DETAILS
                .replace(/:equipmentId/g, res.data.id)
                .replace(/:propertyId/g, props.property.data.id)
                .replace(/:systemId/g, equipment.key),
            {state: {record: res.data}},
        );
    }, [systemForm, resetState, context, navigate, props.property.data.id, equipment.key, equipment.systemFields]);

    /**
     * onSystemPatchSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onSystemPatchSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar("System saved", {variant: "success"});
        // trigger refresh cycle
        systemForm.reset(initSystemForm(equipment.systemFields));
        resetState();
        context.setViewState("REFRESH");
        // redirect
        navigate(
            `${APP_PATHS.EQUIPMENT_DETAILS
                .replace(/:equipmentId/g, res.data.id)
                .replace(/:propertyId/g, props.property.data.id)
                .replace(/:systemId/g, equipment.key)}#system`,
            {state: {record: res.data}},
        );
    }, [systemForm, resetState, context, equipment.systemFields, navigate, equipment.key, props.property.data.id]);

    /**
     * onSystemArchiveSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onSystemArchiveSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${state.record?.data.name} equipment archived`, {variant: "success"});
        // trigger refresh cycle
        systemForm.reset(initSystemForm(equipment.systemFields));
        resetState();
        context.setViewState("REFRESH");
        // redirect
        navigate(APP_PATHS.EQUIPMENT.replace(/:propertyId/g, props.property.data.id));
    }, [systemForm, resetState, context, props.property.data.id, state, navigate, equipment.systemFields]);

    /**
     * onSystemRestoreSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onSystemRestoreSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${state.record?.data.name} equipment restored`, {variant: "success"});
        // trigger refresh cycle
        systemForm.reset(initSystemForm(equipment.systemFields));
        resetState();
        context.setViewState("REFRESH");
    }, [systemForm, resetState, context, state, equipment.systemFields]);

    /**
     * onSystemDeleteSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onSystemDeleteSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${state.record?.data.name} equipment Deleted`, {variant: "success"});
        // trigger refresh cycle
        resetState();
        context.setViewState("REFRESH");
        // redirect
        navigate(APP_PATHS.EQUIPMENT.replace(/:propertyId/g, props.property.data.id));
    }, [context, resetState, props.property.data.id, state, navigate]);

    /**
     * onComponentPostSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onComponentPostSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar("Component saved", {variant: "success"});
        // trigger refresh cycle
        componentForm.reset(initComponentForm((cState.component as Component).fields));
        resetCState();
        context.setViewState("REFRESH");
        // redirect
        navigate(
            `${APP_PATHS.EQUIPMENT_DETAILS
                .replace(/:equipmentId/g, state.record?.data.id)
                .replace(/:propertyId/g, props.property.data.id)
                .replace(/:systemId/g, equipment.key)}#${res.data.id}`,
            {state: {record: state.record?.data}},
        );
    }, [componentForm, resetCState, cState, context, props.property.data.id, navigate, equipment.key, state.record]);

    /**
     * onComponentPatchSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onComponentPatchSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar("Component saved", {variant: "success"});
        // trigger refresh cycle
        componentForm.reset(initComponentForm((cState.component as Component).fields));
        resetCState();
        context.setViewState("REFRESH");
        // redirect
        navigate(
            `${APP_PATHS.EQUIPMENT_DETAILS
                .replace(/:equipmentId/g, state.record?.data.id)
                .replace(/:propertyId/g, props.property.data.id)
                .replace(/:systemId/g, equipment.key)}#${res.data.id}`,
            {state: {record: state.record?.data}},
        );
    }, [componentForm, resetCState, cState, context, props.property.data.id, navigate, equipment.key, state.record]);

    /**
     * onComponentArchiveSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onComponentArchiveSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${res.data.name} component archived`, {variant: "success"});
        // trigger refresh cycle
        componentForm.reset(initComponentForm((cState.component as Component).fields));
        resetCState();
        context.setViewState("REFRESH");
        // redirect
        navigate(
            `${APP_PATHS.EQUIPMENT_DETAILS
                .replace(/:equipmentId/g, state.record?.data.id)
                .replace(/:propertyId/g, props.property.data.id)
                .replace(/:systemId/g, equipment.key)}#${res.data.id}`,
            {state: {record: state.record?.data}},
        );
    }, [context, resetCState, cState, componentForm, navigate, equipment.key, state, props.property]);

    /**
     * onComponentRestoreSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onComponentRestoreSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${res.data.name} component restored`, {variant: "success"});
        // trigger refresh cycle
        resetCState();
        context.setViewState("REFRESH");
        // redirect
        navigate(
            `${APP_PATHS.EQUIPMENT_DETAILS
                .replace(/:equipmentId/g, state.record?.data.id)
                .replace(/:propertyId/g, props.property.data.id)
                .replace(/:systemId/g, equipment.key)}#${res.data.id}`,
            {state: {record: state.record?.data}},
        );
    }, [context, resetCState, navigate, equipment.key, state, props.property.data.id]);

    /**
     * onComponentDeleteSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onComponentDeleteSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${cState.record?.data.name} component deleted`, {variant: "success"});
        // trigger refresh cycle
        resetCState();
        context.setViewState("REFRESH");
        // redirect
        navigate(
            `${APP_PATHS.EQUIPMENT_DETAILS
                .replace(/:equipmentId/g, state.record?.data.id)
                .replace(/:propertyId/g, props.property.data.id)
                .replace(/:systemId/g, equipment.key)}`,
            {state: {record: state.record?.data}},
        );
    }, [context, resetCState, cState, navigate, equipment.key, state, props.property.data.id]);

    /**
     * onSystemPost
     * @param {FieldValues} values
     */
    const onSystemPost=async (values:FieldValues):Promise<void> => {
        try {
            systemCall.call(constructSystemPostConfig(values, props.property, state.bases as AxiosResponse, equipment), values, onSystemPostSuccess, "post");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onSystemUpdate
     * @param {FieldValues} values
     */
    const onSystemUpdate=async (values:FieldValues):Promise<void> => {
        try {
            systemCall.call(constructSystemPatchConfig(values, props.property, state.bases as AxiosResponse, equipment, state.record as AxiosResponse), values, onSystemPatchSuccess, "edit");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onSystemArchiveSubmit
     * @param {FieldValues} values
     * @return {void}
     */
    const onSystemArchiveSubmit= async (values:FieldValues):Promise<void> => {
        try {
            systemCall.call(constructSystemArchiveConfig(values, props.property, equipment, state.record as AxiosResponse, true), values, onSystemArchiveSuccess, "archive");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onSystemRestoreSubmit
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onSystemRestoreSubmit= async (args:React.MouseEvent):Promise<void> => {
        try {
            systemCall.call(constructSystemArchiveConfig(null, props.property, equipment, state.record as AxiosResponse, false), null, onSystemRestoreSuccess, "restore");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onSystemDeleteSubmit
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onSystemDeleteSubmit= async (args:React.MouseEvent):Promise<void> => {
        try {
            systemCall.call(constructSystemDeleteConfig(state.record as AxiosResponse, equipment), null, onSystemDeleteSuccess, "delete");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onComponentPost
     * @param {FieldValues} values
     */
    const onComponentPost=useCallback(async (values:FieldValues):Promise<void> => {
        try {
            componentCall.call(constructComponentPostConfig(values, state.bases as AxiosResponse, state.record as AxiosResponse, equipment, cState.component as Component), values, onComponentPostSuccess, "post");
        } catch (e) {
            console.log(e);
        }
    }, [state, cState, componentCall, equipment, onComponentPostSuccess]);

    /**
     * onComponentUpdate
     * @param {FieldValues} values
     */
    const onComponentUpdate=useCallback(async (values:FieldValues):Promise<void> => {
        try {
            componentCall.call(constructComponentPatchConfig(values, state.bases as AxiosResponse, state.record as AxiosResponse, cState.record as AxiosResponse, equipment, cState.component as Component), values, onComponentPatchSuccess, "edit");
        } catch (e) {
            console.log(e);
        }
    }, [state, cState, componentCall, equipment, onComponentPatchSuccess]);

    /**
     * onComponentArchiveSubmit
     * @param {FieldValues} values
     * @return {void}
     */
    const onComponentArchiveSubmit= async (values:FieldValues):Promise<void> => {
        try {
            componentCall.call(constructComponentArchiveConfig(values, state.record as AxiosResponse, cState.record as AxiosResponse, equipment, cState.component as Component, true), values, onComponentArchiveSuccess, "archive");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onComponentRestoreSubmit
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onComponentRestoreSubmit= async (args:React.MouseEvent):Promise<void> => {
        try {
            componentCall.call(constructComponentArchiveConfig(null, state.record as AxiosResponse, cState.record as AxiosResponse, equipment, cState.component as Component, false), null, onComponentRestoreSuccess, "restore");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onComponentDeleteSubmit
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onComponentDeleteSubmit= async (args:React.MouseEvent):Promise<void> => {
        try {
            componentCall.call(constructComponentDeleteConfig(cState.record as AxiosResponse, equipment, cState.component as Component), null, onComponentDeleteSuccess, "delete");
        } catch (e) {
            console.log(e);
        }
    };

    // system 412 handling
    useEffect(() => {
        if (context.viewState==="PENDING_END" && state.record && state.formValues) {
            if ("edit" in state.formValues) systemCall.call(constructSystemPatchConfig(state.formValues.edit, props.property, state.bases as AxiosResponse, equipment, state.record as AxiosResponse), state.formValues.edit, onSystemPatchSuccess, "edit"); // eslint-disable-line max-len
            else if ("archive" in state.formValues) systemCall.call(constructSystemArchiveConfig(state.formValues.archive, props.property, equipment, state.record, true), state.formValues.archive, onSystemArchiveSuccess, "archive");
            else if ("restore" in state.formValues) systemCall.call(constructSystemArchiveConfig(null, props.property, equipment, state.record, false), null, onSystemRestoreSuccess, "restore");
            else if ("delete" in state.formValues) systemCall.call(constructSystemDeleteConfig(state.record as AxiosResponse, equipment), null, onSystemDeleteSuccess, "delete");
        }
    }, [context.viewState, systemCall, props.property, state.formValues, state.bases, state.record, equipment, onSystemPatchSuccess, onSystemArchiveSuccess, onSystemRestoreSuccess, onSystemDeleteSuccess]);

    // component 412 handling
    useEffect(() => {
        if (context.viewState==="PENDING_END" && cState.record && cState.formValues) {
            if ("edit" in cState.formValues) componentCall.call(constructComponentPatchConfig(cState.formValues.edit, state.bases as AxiosResponse, state.record as AxiosResponse, cState.record as AxiosResponse, equipment, cState.component as Component), cState.formValues.edit, onComponentPatchSuccess, "edit"); // eslint-disable-line max-len
            else if ("archive" in cState.formValues) componentCall.call(constructComponentArchiveConfig(cState.formValues.archive, state.record as AxiosResponse, cState.record as AxiosResponse, equipment, cState.component as Component, true), cState.formValues.archive, onComponentArchiveSuccess, "archive"); // eslint-disable-line max-len
            else if ("restore" in cState.formValues) componentCall.call(constructComponentArchiveConfig(null, state.record as AxiosResponse, cState.record as AxiosResponse, equipment, cState.component as Component, false), null, onComponentRestoreSuccess, "restore"); // eslint-disable-line max-len
            else if ("delete" in cState.formValues) componentCall.call(constructComponentDeleteConfig(cState.record as AxiosResponse, equipment, cState.component as Component), null, onComponentDeleteSuccess, "delete");
        }
    }, [context.viewState, componentCall, cState, props.property, state.bases, state.record, equipment, onComponentPatchSuccess, onComponentArchiveSuccess, onComponentRestoreSuccess, onComponentDeleteSuccess]);

    const COMPONENT_MAX_LIMIT=!(cState.components?.data.results.length<=9);

    /**
     * resolveComponents
     */
    const resolveComponents=useCallback(():any => {
        const content:any={active: [], archived: []};

        // POST ITEM
        if (cState.component!==null && cState.viewMode==="POST_MODE") {
            let label=cState.component.label as string;
            // Resolve label if the component has variants
            if (cState.component?.variant) label=(cState.component?.variant as VariantType).label;
            content.active.push({
                key: COMPONENT_POST_KEY,
                header: {
                    primaryLabel: "New Component",
                    secondaryLabel: label,
                    actions: resolveActions(
                        cState.viewMode,
                        false,
                        {
                            post: {
                                onClick: onComponentPost,
                                onError: (args:FieldErrors) => enqueueSnackbar(FORM_REQUIRED_SNACK_MESSAGE, {variant: "error"}),
                                disabled: state.viewMode==="POST_MODE",
                            },
                            cancel: {
                                onClick: (args:any) => {
                                    componentForm.reset(initComponentForm((cState.component as Component).fields));
                                    setCState({..._.cloneDeep(cState), component: null, viewMode: "VIEW_MODE", record: null, recordUrl: null});
                                },
                                disabled: state.viewMode==="POST_MODE",
                            },
                        },
                    ),
                    viewMode: cState.viewMode,
                },
                content: (
                    <cState.component.form
                        fields={alterEditableFieldsViewMode({base: cState.component.fields}, cState.viewMode).base}
                        bases={state.bases}
                        form={componentForm}
                    />
                ),
                form: componentForm,
            } as SubMenuItem);
        }

        // API EXISTING ITEMS
        if (cState.components?.status===200 && Array.isArray(cState.components.data.results)) {
            let disabled=cState.viewMode!=="VIEW_MODE" || state.viewMode!=="VIEW_MODE";
            let c:Component=equipment.component as Component;
            let secondaryLabel=c.label;
            cState.components.data.results.forEach((item:any) => {
                if (Array.isArray(equipment.component)) {
                    c = equipment.component?.find((i:any) => i.variant.key===item.variant) as Component;
                    secondaryLabel = equipment.component.find((i:any) => i.variant.key===item.variant)?.variant?.label;
                }

                if (cState.record && cState.record?.data.id===item.id) disabled=false;
                else if (cState.record && cState.record?.data.id!==item.id) disabled=true;

                const subMenuItemContent=(
                    <c.form
                        fields={alterEditableFieldsViewMode({base: c.fields}, disabled?"VIEW_MODE":cState.viewMode).base}
                        bases={state.bases}
                        form={cState.record && disabled===false?componentForm:undefined}
                        values={{data: item, status: 200}}
                        dialog={state.dialog}
                    />
                );
                const menuItem:SubMenuItem={
                    key: item.id,
                    header: {
                        primaryLabel: item.name,
                        secondaryLabel,
                        archived: item.archived,
                        actions: resolveActions(
                            disabled?"VIEW_MODE":cState.viewMode,
                            item.archived,
                            {
                                edit: {
                                    onClick: (args:React.MouseEvent) => setCState({..._.cloneDeep(cState), viewMode: "EDIT_MODE", record: {data: item} as AxiosResponse, component: c, recordUrl: item.links.self}),
                                    disabled,
                                },
                                archive: {
                                    onClick: (args:React.MouseEvent):void => {
                                        setCState({..._.cloneDeep(cState), record: {data: item} as AxiosResponse, component: c, recordUrl: item.links.self});
                                        onDialogClick("COMPONENT_ARCHIVE")(args);
                                    },
                                    disabled,
                                },
                                restore: {
                                    onClick: (args:React.MouseEvent):void => {
                                        setCState({..._.cloneDeep(cState), record: {data: item} as AxiosResponse, component: c, recordUrl: item.links.self});
                                        onDialogClick("COMPONENT_RESTORE")(args);
                                    },
                                    disabled,
                                },
                                delete: {
                                    onClick: (args:React.MouseEvent):void => {
                                        setCState({..._.cloneDeep(cState), record: {data: item} as AxiosResponse, component: c, recordUrl: item.links.self});
                                        onDialogClick("COMPONENT_DELETE")(args);
                                    },
                                    disabled,
                                },
                                duplicate: {
                                    onClick: (args:React.MouseEvent) => {
                                        const trimmedItem=_.omit(item, ["id", "name", "tag_id", "etag_version", "created_on", "created_by_email"]);
                                        componentForm.reset(initComponentForm(c.fields, c.resolveValues(trimmedItem, c.fields, state.bases)));
                                        setCState({..._.cloneDeep(cState), component: item.variant?{...c, variant: (c?.variant as VariantType)}:c, viewMode: "POST_MODE"});
                                        enqueueSnackbar("Component duplicated", {variant: "success"});
                                        scrollTo(COMPONENT_POST_KEY, "top");
                                    },
                                    disabled: disabled || COMPONENT_MAX_LIMIT,
                                },
                                discard: {
                                    onClick: (args:React.MouseEvent) => {
                                        componentForm.reset(initComponentForm(c.fields));
                                        setCState({..._.cloneDeep(cState), viewMode: "VIEW_MODE", component: null, record: null, recordUrl: null});
                                    },
                                    disabled,
                                },
                                update: {
                                    onClick: onComponentUpdate,
                                    onError: (args:FieldErrors) => enqueueSnackbar(FORM_REQUIRED_SNACK_MESSAGE, {variant: "error"}),
                                    disabled,
                                },
                            },
                        ),
                        viewMode: disabled?"VIEW_MODE":cState.viewMode,
                    },
                    content: (cState.viewMode==="POST_MODE"
                        ?subMenuItemContent
                        :<WithLog bases={state.bases} component={subMenuItemContent} record={{data: item} as AxiosResponse} resource={c.key as Resource} />
                    ),
                    form: cState.record && disabled===false?componentForm:undefined,
                };

                if (item.archived) content.archived.push(menuItem);
                else content.active.push(menuItem);
            });
        }

        return content;
    }, [cState, componentForm, equipment.component, onComponentPost, onComponentUpdate, onDialogClick, state.bases, state.dialog, state.viewMode, COMPONENT_MAX_LIMIT]);

    // menu content
    const disabled=cState.viewMode!=="VIEW_MODE";
    const menuItemContent=(
        <equipment.system
            bases={state.bases?.data.results.map((i:any) => ({primary: i.software, secondary: i.version, ...i.archived===true && {tertiary: BAS_ARCHIVED_LABEL}} as OptionType))}
            fields={formFields.systemFields}
            form={systemForm}
            values={state.record}
        />
    );
    let menu:MenuItem[]=[
        {
            key: "system",
            label: "System",
            icon: HeatPumpOutlined,
            header: {
                actions: resolveActions(
                    state.viewMode,
                    state.record?.data.archived,
                    {
                        edit: {onClick: (args:React.MouseEvent) => setState({..._.cloneDeep(state), viewMode: "EDIT_MODE"}), disabled},
                        archive: {onClick: onDialogClick("EQUIPMENT_ARCHIVE"), disabled},
                        restore: {onClick: onDialogClick("EQUIPMENT_RESTORE"), disabled},
                        delete: {onClick: onDialogClick("EQUIPMENT_DELETE"), disabled},
                        duplicate: {onClick: (args:React.MouseEvent) => {
                            enqueueSnackbar("Building System duplicated", {variant: "success"});
                            navigate(
                                `${APP_PATHS.EQUIPMENT_POST
                                    .replace(/:equipmentId/g, state.record?.data.id)
                                    .replace(/:propertyId/g, props.property.data.id)
                                    .replace(/:systemId/g, equipment.key)}`,
                                {state: {record: state.record?.data, action: "duplicate"}},
                            );
                        },
                        disabled},
                        discard: {onClick: (args:React.MouseEvent) => setState({..._.cloneDeep(state), viewMode: "VIEW_MODE"}), disabled},
                        update: {onClick: onSystemUpdate, onError: (args:FieldErrors) => enqueueSnackbar(FORM_REQUIRED_SNACK_MESSAGE, {variant: "error"}), disabled},
                        cancel: {onClick: (args:React.MouseEvent) => navigate(APP_PATHS.EQUIPMENT.replace(/:propertyId/g, props.property.data.id), {state: {isPostCanceled: true}}), disabled},
                        post: {onClick: onSystemPost, onError: (args:FieldErrors) => enqueueSnackbar(FORM_REQUIRED_SNACK_MESSAGE, {variant: "error"}), disabled},
                    },
                ),
                primaryLabel: state.record?.data?.name||"New System",
                secondaryLabel: equipment.label,
                archived: state.record?.data?.archived,
                viewMode: state.viewMode,
            },
            content: (state.viewMode==="POST_MODE"
                ?menuItemContent
                :<WithLog bases={state.bases} component={menuItemContent} record={state.record} resource={equipment.key as Resource} />
            ),
            form: systemForm,
        },
    ];

    let componentContent;
    if (equipment.component) {
        componentContent=resolveComponents();
        const isPost:MenuItem|undefined=componentContent.active.find((m:MenuItem) => m.header?.viewMode==="POST_MODE");

        menu=[
            ...menu,
            {
                key: "components",
                label: "Components",
                icon: GridViewOutlined,
                header: {
                    primaryLabel: "Components",
                    ...componentContent.active.length>=1 && {actions: [{
                        key: "add-component",
                        label: "ADD COMPONENT",
                        startIcon: <AddOutlined />,
                        onClick: onDialogClick("COMPONENT_LIST"),
                        variant: "contained",
                        disabled: state.viewMode!=="VIEW_MODE" || isPost!==undefined || cState.viewMode!=="VIEW_MODE" || COMPONENT_MAX_LIMIT,
                    }]},
                },
                emptyState: (
                    <EmptyStateBlock
                        header="No components for this system."
                        icon={GridViewOutlined}
                        primaryAction={{
                            onClick: onDialogClick("COMPONENT_LIST"),
                            label: "ADD COMPONENT",
                            startIcon: <AddOutlined />,
                            disabled: state.viewMode!=="VIEW_MODE" || isPost!==undefined || cState.viewMode!=="VIEW_MODE",
                        }}
                    />
                ),
                content: componentContent.active,
            },
            {
                key: "archived",
                label: BAS_ARCHIVED_LABEL,
                icon: ArchiveOutlined,
                emptyState: (
                    <EmptyStateBlock
                        header="No archived components."
                        icon={ArchiveOutlined}
                    />
                ),
                content: componentContent.archived,
            },
        ];
    }

    // pure post escape
    if (params.equipmentId && (
        state.record===null
        || state.bases===null
        || systemForm.formState.defaultValues===undefined
        || (equipment.component && cState.components===null)
        || (equipment.component && cState.components?.data.results.length===undefined
        ))) return <LinearProgress />;

    // duplicate post escape
    if ((!("equipmentId" in params) && location.state?.record && location.state.action==="duplicate") && (
        state.record===null
        || state.bases===null
        || systemForm.formState.defaultValues===undefined
    )) return <LinearProgress />;

    return (
        <Box>
            {/* system - archive dialog */}
            {context.viewState==="NONE" && state.record?.data && (
                <Archive
                    status={state.dialog}
                    dialogType="EQUIPMENT_ARCHIVE"
                    onClose={onDialogClick("NONE")}
                    onSubmit={onSystemArchiveSubmit}
                    title={state.record?.data.name}
                    message={(
                        <Box>
                            <Typography variant="body1">
                                Any associated components will also be archived. Archived equipment will not be included in exports or reports for this property. BAS association is maintained.
                            </Typography>
                            <Typography variant="body1">Archived equipment can be restored.</Typography>
                        </Box>
                    )}
                    reasons={ARCHIVED_REASONS}
                />
            )}
            {/* system - restore dialog */}
            {context.viewState==="NONE" && state.record?.data && (
                <Prompt
                    status={state.dialog}
                    dialogType="EQUIPMENT_RESTORE"
                    onClose={onDialogClick("NONE")}
                    onSubmit={{onClick: onSystemRestoreSubmit, label: "Restore"}}
                    title={`Restore ${state.record?.data.name}`}
                    message={(<Typography variant="body1">Any associated components will remain archived and can be restored individually. Restored equipment will be included in exports or reports for this property.</Typography>)}
                />
            )}
            {/* system - delete dialog */}
            {context.viewState==="NONE" && state.record?.data && (
                <Prompt
                    status={state.dialog}
                    dialogType="EQUIPMENT_DELETE"
                    onClose={onDialogClick("NONE")}
                    onSubmit={{onClick: onSystemDeleteSubmit, label: "Delete"}}
                    title={`Delete ${state.record?.data.name}`}
                    message={(<Typography variant="body1">This action cannot be undone.</Typography>)}
                />
            )}
            {/* component - add-component dialog */}
            <AddComponentFilter
                status={state.dialog}
                dialogType="COMPONENT_LIST"
                onClose={onDialogClick("NONE")}
                onSubmit={onAddComponentSubmit}
                equipment={equipment}
            />
            {/* component - archive dialog */}
            {context.viewState==="NONE" && cState.record?.data && (
                <Archive
                    status={state.dialog}
                    dialogType="COMPONENT_ARCHIVE"
                    onClose={(args:React.MouseEvent) => {
                        setCState({..._.cloneDeep(cState), record: null, component: null, recordUrl: null});
                        onDialogClick("NONE")(args);
                    }}
                    onSubmit={onComponentArchiveSubmit}
                    title={cState.record?.data.name}
                    message={(
                        <Box>
                            <Typography variant="body1">Archived equipment will not be included in exports or reports for this property. BAS association is maintained.</Typography>
                            <Typography variant="body1">Archived equipment can be restored.</Typography>
                        </Box>
                    )}
                    reasons={ARCHIVED_REASONS}
                />
            )}
            {/* component - restore dialog */}
            {context.viewState==="NONE" && cState.record?.data && (
                <Prompt
                    status={state.dialog}
                    dialogType="COMPONENT_RESTORE"
                    onClose={(args:React.MouseEvent) => {
                        setCState({..._.cloneDeep(cState), record: null, component: null, recordUrl: null});
                        onDialogClick("NONE")(args);
                    }}
                    onSubmit={{onClick: onComponentRestoreSubmit, label: "Restore"}}
                    title={`Restore ${cState.record?.data.name}`}
                    message={(<Typography variant="body1">Restored equipment will be included in exports or reports for this property.</Typography>)}
                />
            )}
            {/* component - delete dialog */}
            {context.viewState==="NONE" && cState.record?.data && (
                <Prompt
                    status={state.dialog}
                    dialogType="COMPONENT_DELETE"
                    onClose={(args:React.MouseEvent) => {
                        setCState({..._.cloneDeep(cState), record: null, component: null, recordUrl: null});
                        onDialogClick("NONE")(args);
                    }}
                    onSubmit={{onClick: onComponentDeleteSubmit, label: "Delete"}}
                    title={`Delete ${cState.record?.data.name}`}
                    message={(<Typography variant="body1">This action cannot be undone.</Typography>)}
                />
            )}
            {/* lock */}
            <Backdrop open={!["NONE"].includes(context.viewState)} />
            {/* content */}
            {context.viewState==="NONE" && (
                <WithMenu menu={menu} />
            )}
        </Box>
    );
}

export default EquipmentDetails;
