import React, {useState, useEffect, Dispatch, SetStateAction} from "react";
import {AxiosResponse} from "axios";
import moment from "moment";
import _ from "lodash";
import {Grid, Typography, Box} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Button} from "../generics/inputs";
import {CHANGE_LOG} from "../../config";
import Dialog from "../generics/Dialog";
import useCall, {BaseState} from "../generics/useCall";
import {resolveLogs} from "../../handlers";
import {DialogType} from "../../types";

export const LOG_COLUMNS_DEF:GridColDef[]=[
    {field: "date_time", headerName: "Date Time", flex: 1},
    {field: "field_modified", headerName: "Field Modified", flex: 1},
    {field: "old_val", headerName: "Original Value", flex: 1},
    {field: "new_val", headerName: "New Value", flex: 1},
    {field: "user_", headerName: "User", flex: 1},
];

const RESOURCE_KEYS:Record<any, any>={dhw: "domestic_hot_water"};

export type Resource="organization"|"property"|"propertydocument"|"meterinfo"|"bas"|"ahu"|"acchiller"|"avb"
    |"chilledbeam"|"chwloop"|"conwloop"|"cuh"|"districtsteam"|"dhw"|"eru"|"exhaust_fan"|"fcu"
    |"heatpump"|"hvu"|"hwboiler"|"hwd"|"ptac"|"pu"|"radiantunit"|"swboiler"|"vrfu"|"wcchiller"|"wccondenser"
    |"ahu_fan"|"avb_discharge_fan"|"chwloop_pump"|"conwloop_pump"|"cuh_discharge_fan"|"fcu_discharge_fan"
    |"heatpump_discharge_fan"|"hvu_fan"|"hwpump"|"ptac_discharge_fan"|"pu_fan"|"vrfu_discharge_fan";

interface Props{
    resource:Resource
    record:AxiosResponse|null
    bases?:AxiosResponse|null
}

export interface State extends Omit<BaseState, "record"|"formValues">{
    log:AxiosResponse|null
}

/**
 * ChangeLog
 * @param {Props} props
 * @return {React.ReactElement}
 */
function ChangeLog(props:Props):React.ReactElement {
    const [state, setState]:[State, Dispatch<SetStateAction<State>>]=useState<State>({
        log: null,
        dialog: "NONE",
    });
    const {get}=useCall({state, setState: setState as any});

    /**
     * onChnageLog
     * @param {DialogType} dialog
     * @return {void}
     */
    const onChnageLog=(dialog:DialogType) => (args:React.MouseEvent):void => setState({..._.cloneDeep(state), dialog});

    useEffect(() => {
        if (state.log===null && props.record && state.dialog==="CHANGE_LOG") {
            get(`${CHANGE_LOG}?parent_id=${props.record.data.id}&resource_=${Object.keys(RESOURCE_KEYS).includes(props.resource)?RESOURCE_KEYS[props.resource]:props.resource}`, "log", true);
        }
    }, [get, props.record, props.resource, state.log, state.dialog]);

    return (
        <Box>
            <Grid container sx={{marginTop: "12px", marginBottom: "25px"}} spacing={3} direction="column" justifyContent="center" alignItems="center">
                {/* archived_reason */}
                {props.record?.data.archived && (
                    <Grid item><Typography variant="body1">{`Archived Reason: ${props.record.data.archived_reason}`}</Typography></Grid>
                )}
                {/* label updated on */}
                {props.record?.data.last_updated_on && (
                    <Grid item><Typography variant="body1">{`Last Updated: ${moment(props.record.data.last_updated_on).format("M/D/YYYY h:mm A")}`}</Typography></Grid>
                )}
                {/* dialog trigger */}
                <Grid item>
                    <Button
                        label="VIEW CHANGELOG"
                        variant="outlined"
                        color="secondary"
                        disabled={state.log!==null && state.log?.status!==200}
                        onClick={onChnageLog("CHANGE_LOG")}
                    />
                </Grid>
            </Grid>
            {/* Dialog */}
            <Dialog
                open={state.dialog==="CHANGE_LOG"}
                onClose={{onClick: onChnageLog("NONE"), label: "Close"}}
                title={(
                    <Grid container direction="column">
                        <Grid item>Changelog</Grid>
                        <Grid item><Typography variant="subtitle2">{props.record?.data.name}</Typography></Grid>
                    </Grid>
                )}
                maxWidth="md"
                content={(
                    <DataGrid rows={resolveLogs(state.log, props.bases)} columns={LOG_COLUMNS_DEF} />
                )}
            />
        </Box>
    );
}

ChangeLog.defaultProps={
    bases: null,
};

export default ChangeLog;
