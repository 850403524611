import React, {useEffect, useState, Dispatch, SetStateAction} from "react";
import {Box} from "@mui/material";
import {SummarizeOutlined} from "@mui/icons-material";
import {useParams} from "react-router-dom";
import {AxiosResponse} from "axios";
import {PROPERTY_CAMPUS_ID_PROPERTIES} from "../config";
import {ParentProps} from "../types";
// components
import {Campus as CampusForm, CampusFields} from "../components/forms";
import WithMenu from "../components/layout/WithMenu";
import ChangeLog from "../components/layout/ChangeLog";
import useCall, {BaseState} from "../components/generics/useCall";
import {cleanValue} from "../handlers";

interface Props extends ParentProps{
    // NOTE: Empty For future expansion.
}

export interface State extends Omit<BaseState, "dialog"|"record"|"formValues">{
    properties:AxiosResponse|null
}

/**
 * Campus
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Campus(props:Props):React.ReactElement {
    const [state, setState]:[State, Dispatch<SetStateAction<State>>]=useState<State>({
        properties: null,
    });
    const {get}=useCall({state, setState: setState as any});
    const params:any=useParams();

    // resolve values
    const values=CampusFields.reduce(
        (a:any, v:any) => {
            let cv:any=cleanValue(props.property.data[v.key]);
            if (v.key==="building_size_sqft") cv=cv?.toLocaleString()||"";

            return ({
                ...a,
                [v.key]: cv,
            });
        },
        {},
    );

    useEffect(() => {
        if (state.properties===null) get(`${PROPERTY_CAMPUS_ID_PROPERTIES.replace(/{id}/g, params.propertyId)}`, "properties", true);
    }, [get, params.propertyId, state.properties]);

    if (state.properties?.data) values.number_of_properties=state.properties?.data.total_count;

    return (
        <Box>
            <WithMenu
                menu={[
                    {
                        key: "property-details",
                        label: "Property Details",
                        header: {
                            primaryLabel: `${props.property.data.name}`,
                            secondaryLabel: `${props.property.data.recordtype}`,
                            rootSticky: true,
                        },
                        subHeader: {
                            label: "Property Details",
                        },
                        icon: SummarizeOutlined,
                        content: <CampusForm values={values} />,
                    },
                ]}
                log={<ChangeLog record={props.property} resource="property" />}
            />
        </Box>
    );
}

export default Campus;
