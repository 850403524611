import React, {memo} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography} from "@mui/material";
import * as yup from "yup";
import {useForm, UseFormReturn} from "react-hook-form";
import {ArrowDropDown} from "@mui/icons-material";
import {Field, ControlledTextField, ControlledToggleButton, ToggleButtonGroup} from "../../generics/inputs";
import {POINT_BOOLEANS} from "../../../config";
import {populateValues, resolveFormKey} from "../../../handlers";

export interface Selector {
    key:string,
    value:string|boolean,
    label:string
}

interface Props{
    form:UseFormReturn
    fields:Field[]
    label:string
    pointKey:string
    selectors:Selector[]
    onSelectorChange: (value:Selector) => (args:React.MouseEvent) => void
}

interface HeaderProps{
    fields:Field[]
    breaks:any[]
    selectors:Selector[]
    onSelectorChange: (value:Selector) => (args:React.MouseEvent) => void
}

export const Fields:Field[] = [
    {
        key: "tag_id",
        label: "Point Tag/ID",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            fieldLabel: true,
        },
    },
    {
        key: "point_booleans",
        label: "Point Booleans",
        yup: yup.mixed().nullable(),
        type: "toggle",
        toggleOptions: {
            disabled: true,
            options: [],
        },
    },
    {
        key: "notes",
        label: "Note",
        yup: yup.string(),
        type: "textfield",
        textfieldOptions: {
            readOnly: true,
            fieldLabel: true,
        },
    },
];

/**
 * Header
 * @param {HeaderProps} props
 * @return {React.ReactElement|null}
 */
function Header(props:HeaderProps):React.ReactElement|null {
    // resolve form key
    const {key}:any=resolveFormKey(props.fields[0].key);
    if (key.index!=="0") return null;
    // Global Selectors
    const selectors=(
        <Grid
            sx={{paddingLeft: "7px",
                ".MuiAccordionSummary-root.Mui-expanded": {minHeight: "38px"},
                ".MuiPaper-root": {boxShadow: "none"},
                ".Mui-disabled": {backgroundColor: "transparent !important"}}}
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
            spacing={1}
        >
            <Accordion disabled={props.fields[key.index]?.textfieldOptions?.readOnly} sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<ArrowDropDown />}
                    sx={{".MuiAccordionSummary-expandIconWrapper": {position: "absolute", right: "0"}, padding: "0px", minHeight: "38px", ".MuiAccordionSummary-content": {margin: "0px"}, ".Mui-expanded.MuiAccordionSummary-content": {margin: "0px"}}}
                >
                    <Grid container direction="row" justifyContent="space-around" alignItems="flex-start" spacing={1}>
                        {POINT_BOOLEANS.map((i:any) => (
                            <Grid item key={`${i.key}-selector`} xs={Math.round(12/POINT_BOOLEANS.length)}>
                                <Typography variant="body1">
                                    {i.label}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{padding: "0px", marginTop: "18px"}}>
                    <Grid container direction="row" justifyContent="space-around" alignItems="flex-start" spacing={1}>
                        {POINT_BOOLEANS.map((i:any) => (
                            <Grid item key={`${i.key}-selector`} xs={Math.round(12/POINT_BOOLEANS.length)}>
                                <ToggleButtonGroup value={props.selectors.find((selector:Selector) => selector.key === i.key)?.value} disabled={props.fields[key.index]?.textfieldOptions?.readOnly} onChange={props.onSelectorChange(i)} />
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );

    // HEADER
    return (
        <Grid container sx={{marginBottom: "24px"}} spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item {...props.breaks[0]}><Typography variant="body1">Point Name</Typography></Grid>
            {props.fields.map((field:Field, idx:number) => {
                if (idx===1) {
                    return (
                        <Grid item {...props.breaks[idx+1]} sx={{paddingLeft: "7px"}} key={`${field.key}-${key.index}-header`}>
                            {selectors}
                        </Grid>
                    );
                }
                return (
                    <Grid item {...props.breaks[idx+1]} sx={{paddingLeft: idx===2?"18px":"6px"}} key={`${field.key}-${key.index}-header`}>
                        <Typography variant="body1">{field.label}</Typography>
                    </Grid>
                );
            })}
        </Grid>
    );
}

/**
 * Point
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Point(props:Props):React.ReactElement {
    let form:UseFormReturn=useForm({mode: "onSubmit"});
    if (props.form) form=props.form;

    // set field options
    populateValues(POINT_BOOLEANS.map((i:any) => i.label), props.fields, "point_booleans");

    // breakpoints
    const breaks=[
        {xs: 2.75, sm: 2.75, md: 2.75, lg: 2.75, xl: 2.75},
        {xs: 1.25, sm: 1.25, md: 1.25, lg: 1.25, xl: 1.25},
        {xs: 5, sm: 5, md: 5, lg: 5, xl: 5},
        {xs: 3, sm: 3, md: 3, lg: 3, xl: 3},
    ];

    return (
        <Box>
            {/* header */}
            <Header fields={props.fields} breaks={breaks} selectors={props.selectors} onSelectorChange={props.onSelectorChange} />
            <Box
                sx={{
                    height: "80px",
                    marginTop: "15px",
                    paddingTop: "12px",
                    backgroundColor: {
                        "&:hover": {
                            backgroundColor: "#eeeeee",
                            marginLeft: "-32px",
                            marginRight: "-32px",
                            paddingLeft: "32px",
                            paddingRight: "32px",
                        },
                    },
                }}
            >
                <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {/* fields */}
                    {/* point key */}
                    <Grid item {...breaks[0]} sx={{marginTop: "16px"}}><Typography variant="body1">{props.label}</Typography></Grid>
                    {/* point form fields */}
                    {props.fields.map((field:Field, idx:number) => {
                        if (field.type==="toggle") {
                            return (
                                <Grid item sx={{margin: 0, alignItems: "center", justifyContent: "center"}} key={`${field.key}`} {...breaks[idx+1]}>
                                    <ControlledToggleButton field={field} control={form.control} />
                                </Grid>
                            );
                        }
                        if (field.type==="textfield") return <Grid item key={`${field.key}`} {...breaks[idx+1]}><ControlledTextField field={field} control={form.control} errors={form.formState.errors} /></Grid>;
                        return null;
                    })}
                </Grid>
            </Box>
        </Box>
    );
}

// /**
//  * arePropsEqual
//  * @param {Props} prevProps
//  * @param {Props} nextProps
//  * @return {boolean}
//  */
// const arePropsEqual=(prevProps:Props, nextProps:Props):boolean => true;

const Memoize=memo((props:Props) => <Point {...props} />);

export default Memoize;
