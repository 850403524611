import {AxiosError, AxiosResponse} from "axios";
import {enqueueSnackbar} from "notistack";
import {User} from "@auth0/auth0-react";
import {env, SLACK_ROUTE} from "../../config";
import api from "../../api";

/**
 * slackCall
 * @param {AxiosResponse} payload
 * @param {User} user
 * @return {Promise<void>}
 */
const slackCall=async (payload:AxiosResponse, user:User):Promise<void> => {
    await api({
        method: "post",
        url: SLACK_ROUTE,
        data: {
            blocks: [
                {type: "header", text: {type: "plain_text", text: "Log", emoji: true}},
                {
                    type: "context",
                    elements: [
                        {type: "plain_text", text: `User Email: ${user.email}`, emoji: true},
                        {type: "plain_text", text: `Environment: ${env.REACT_APP_ENVIRONMENT}`, emoji: true},
                        {type: "plain_text", text: "@zxc", emoji: true},
                    ],
                },
                {type: "divider"},
                {
                    type: "rich_text",
                    elements: [
                        {
                            type: "rich_text_preformatted",
                            elements: [
                                {type: "text", text: `${JSON.stringify(payload, null, 2)}`},
                            ],
                        },
                    ],
                },
            ],
        },
    })
        .then((res:AxiosResponse) => res)
        .catch((err:AxiosError) => enqueueSnackbar("Failed Reporting Error", {variant: "error"}));
};

export default slackCall;
