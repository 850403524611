import {ColInfo, utils, WorkBook, WorkSheet, writeFileXLSX} from "xlsx";
import {AxiosResponse} from "axios";
import moment from "moment";
import {EQUIPMENTS, SCHEDULE_KEYS, WEEK_KEYS} from "../components/forms";
import {
    AIR_HANDLING_FIELDS,
    BAS_FIELDS,
    COMPONENT_FIELDS,
    COOLING_FIELDS,
    DOCUMENTS_FIELDS,
    HEATING_FIELDS,
    KEYS,
    PROPERTY_FIELDS,
    REAL_TIME_METER_FIELDS,
    SYSTEM_KEYS,
    TERMINAL_UNITS_FIELDS} from "../config/inventoryExportKey";
import {EQUIPMENT_CATEGORY_MAP} from "../config";
import * as columnMap from "../config/columnMap";
import {Field} from "../components/generics/inputs";
import {EquipmentType} from "../types";

interface SheetType{
    data:any
    fields:Field[]|null
    title:string
}

// Merge all of the columnMaps
const COLUMN_MAP_DATA:any = [];
Object.keys(columnMap).forEach((x:any) => COLUMN_MAP_DATA.push(columnMap[x as keyof typeof columnMap]));
const COLUMN_MAP = COLUMN_MAP_DATA.flat().filter((x:any) => Object.keys(x).includes("key"));

/**
 * getColumnMap
 * @param {string} key
 * @return {any}
 */
const getColumnMap = (key:string):any => COLUMN_MAP.find((x:any) => x.key === key);

/**
 * resolvePointName
 * @param {string} key
 * @param {string} pointOptions
 * @return {any}
 */
const resolvePointName = (key:string, pointOptions:any, componentName?:string|null):any => {
    if (pointOptions) {
        const option = pointOptions.find((opt:any) => opt.key === key);
        if (option) return option?.label;
    }
    if (componentName) return `${componentName} ${getColumnMap(key).label}`;
    return getColumnMap(key)?.label;
};

/**
 * resolveFieldKeysFromApi
 * @param {keyMap} any
 * @return {string}
 */
const resolveFieldKeysFromApi = (keyMap:any, data:any):any => {
    let value = "";
    const COLUMN_MAP_KEYS = keyMap.map((x:any) => x.key);
    for (let i = 0; i < COLUMN_MAP_KEYS.length; i += 1) {
        const {label} = getColumnMap(COLUMN_MAP_KEYS[i]);
        if (data[COLUMN_MAP_KEYS[i]]) {
            value += `${value ? ", " : ""}${label}`;
        }
    }
    return value;
};

/**
 * resolveOccupancySchedule
 * @param {any} data
 * @return string
 */
const resolveOccupancySchedule = (data:any):string => {
    const WEEK_DAYS_ABBREVIATED = ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"];
    const WEEK_SECONDARY_KEYS = WEEK_KEYS.map((x:any, i:number) => ({...x, secondaryLabel: WEEK_DAYS_ABBREVIATED[i]}));
    const obj:Record<any, any> = {};
    let concatenatedObj = "";
    SCHEDULE_KEYS.forEach((key:string) => {
        if (!data[key]) return;
        const day = WEEK_SECONDARY_KEYS.find((x:any) => x.key === key.split("_")[0])?.secondaryLabel;
        const time = moment(data[key], "H").format("ha");
        if (day && key.split("_")[1] === "start") obj[day] = {...obj[day], start: time};
        else if (day && key.split("_")[1] === "end") obj[day] = {...obj[day], end: time};
    });
    Object.keys(obj).forEach((key:any) => { concatenatedObj += `${key} ${obj[key].start}-${obj[key].end}, `; });
    return concatenatedObj.slice(0, -2);
};

/**
 * convertDataToJson
 * @param {any} data
 * @param {Field[]} fields
 * @param {any} row
 * @param {string|null} itemKey?
 * @param {any} options?
 * @return {Record<string, any>}
 */
const convertDataToJson = (data:any, fields:Field[], row:any, itemKey?:string|null, options?:any):Record<string, any> => {
    const JSON:Record<string, any> = {Property_BDBID: row.id};

    if (options) options.forEach((option:any) => { JSON[option.key] = option.label; });
    let system:EquipmentType|undefined;

    fields.forEach((field:any) => {
        let value = data[field.key];

        if (getColumnMap(data[field.key])) value = getColumnMap(data[field.key]).label;
        if (field.key === "operation_times") value = resolveFieldKeysFromApi(columnMap.OPERATION_TIMES, data);
        if (field.key ==="heating_method") value = resolveFieldKeysFromApi(columnMap.HEATING_METHODS, data);
        if (field.key ==="humidity_control") value = resolveFieldKeysFromApi(columnMap.HUMIDITY_CONTROL, data);
        if (field.key === "communication_protocols") value = resolveFieldKeysFromApi(columnMap.COMMUNICATION_PROTOCOLS, data);
        if (field.key === "oa_control") {
            const isMulti = system?.systemFields.find((x:any) => x.key === "oa_control")?.autocompleteOptions?.multiple;
            if (isMulti) value = resolveFieldKeysFromApi(columnMap.OA_CONTROL_MULTI_SELECT, data);
            else value = getColumnMap(data.oa_control)?.label;
        }
        if (field.key ==="m_start") value = resolveOccupancySchedule(data);
        if (SYSTEM_KEYS.includes(itemKey)) {
            const categoryKey = EQUIPMENTS.find((x:any) => x.key === itemKey)?.category;
            system = EQUIPMENTS.find((x:any) => {
                if (data.variant) { return x.key === itemKey && x.variant === data.variant; }
                return x.key === itemKey;
            });
            if (field.key === "category") value = EQUIPMENT_CATEGORY_MAP.find((x:any) => x.category === categoryKey)?.label;
            if (field.key === "system") value = system?.label;
        }

        // Resolve key/label values.
        JSON[`${field.exportOptions.label ? field.exportOptions.label : field.label}`] = value;
    });
    if (data.created_on) JSON["Created On"] = moment(data.created_on).format("YYYY-MM-DD:HH:mm");
    if (data.last_updated_on) JSON["Last Updated On"] = moment(data.last_updated_on).format("YYYY-MM-DD:HH:mm");

    return JSON;
};

/**
* convertCollectionToJson
* @param {any} collection
* @param {Field[]} fields
* @param {any} row
* @return {[]|null}
*/
const convertCollectionToJson = (collection:any, fields:Field[], row?:any):[]|null => {
    if (!collection) return null;
    const COLLECTION_ARRAY:any = [];
    Object.keys(collection).forEach((item:any) => {
        collection[item]?.forEach((collectionData:any) => COLLECTION_ARRAY.push(convertDataToJson(collectionData, fields, row, item)));
    });
    return COLLECTION_ARRAY;
};

/**
* convertJsonToExcelSheet
* @param {any} jsonData
* @param {Field[]} fields
* @param {any} row
* @return {Worksheet|null}
*/
const convertJsonToExcelSheet = (jsonData:any, fields:Field[], row:any, returnDataObject?:boolean):any => {
    let excelSheet;
    if (Array.isArray(jsonData)) excelSheet = jsonData.map((x:any) => convertDataToJson(x, fields, row));
    else excelSheet = convertCollectionToJson(jsonData, fields, row);
    if (returnDataObject) return excelSheet;
    if (excelSheet && excelSheet.length) return utils.json_to_sheet(excelSheet);
    return null;
};

/**
* getComponentSystemById
* @param {any} item
* @param {any} data
* @param {string} key
* @return {EquipmentType|undefined}
*/
const getComponentSystemById = (item:any, data:any, key:string):EquipmentType|undefined => {
    const componentKey = KEYS.components[key];
    return data[componentKey]?.find((x: any) => x.id === item[`${componentKey}_id`]);
};

/**
* getSystemField
* @param {EquipmentType} system
* @param {boolean} isSystem
* @param {string} key
* @return {EquipmentType|undefined}
*/
const getSystemField = (system:EquipmentType, isSystem:boolean, key:string):EquipmentType|undefined => {
    const keyToUse = isSystem ? key : KEYS.components[key];
    return EQUIPMENTS.find((equipment:any) => {
        if (system.variant) {
            return system.variant === equipment.variant && equipment.key === keyToUse;
        }
        return equipment.key === keyToUse;
    });
};

/**
* collectAndResolveComponents
* @param {any} data
* @param {Field[]} fields
* @param {any} row
* @return {any}
*/
const collectAndResolveComponents = (data:any, fields:Field[], row:any):any => {
    const totalResult:any=[];
    Object.keys(data).forEach((key:string) => {
        if (!(key in KEYS.components)) return;
        if (Array.isArray(data[key])) {
            data[key].forEach((item:any) => {
                if (item.archived) return; // Filter items if they are archived.

                const system = data[KEYS.components[key]].find((x:any) => x.id === item[`${KEYS.components[key]}_id`]);
                const systemField = getSystemField(system, true, KEYS.components[key]);

                let componentType;
                if (systemField && Array.isArray(systemField.component)) {
                    componentType = systemField.component.find((x:any) => x.variant.key === item.variant)?.variant?.label;
                } else if (systemField && !Array.isArray(systemField.component)) componentType = systemField.component?.label;

                // Attach custom data
                const customData = item;
                customData.systemId = system.tag_id;
                customData.system = systemField?.label;
                customData.componentType = componentType;

                const component = convertDataToJson(customData, fields, row, null);

                totalResult.push(component);
            });
        }
    });
    return totalResult;
};

/**
* collectAndResolvePoints
* @param {any} data
* @param {any} row
* @return {any}
*/
const collectAndResolvePoints = (data:any, row:any):any => {
    const totalResult:any=[];
    Object.keys(data).forEach((key:string) => {
        // Exit if not a system or a component
        if (![...SYSTEM_KEYS].includes(key) && !(key in KEYS.components)) return;

        // If an array, and not null -- proceed:
        if (Array.isArray(data[key])) {
            const isSystem = [...SYSTEM_KEYS].includes(key);

            // Loop through the systems or components array:
            data[key].forEach((item:any) => {
                if (item.archived) return; // Filter items if they are archived.

                // Determine the current system (or fetch the related one if it's a component)
                const system = isSystem ? item : getComponentSystemById(item, data, key);

                let componentName:string|null;
                let component:any|null;

                if (!isSystem) { component = item; } // Assign the component if it's not a system

                const systemField = getSystemField(system, isSystem, key);

                if (component && systemField) {
                    // Determine the component name based on whether it has variants or not.
                    if (Array.isArray(systemField?.component)) {
                        componentName = systemField.component.find((x: any) => x.variant.key === component.variant)?.variant?.label || null;
                    } else {
                        componentName = systemField?.component?.label || null;
                    }
                }

                const pointsOptions = systemField?.systemFields.find((x:any) => x.key === "points")?.pointsOptions?.options;

                if (item.points && Object.keys(item.points).length > 0) {
                    Object.keys(item.points).forEach((point:any) => {
                        const p:Record<string, any> = {
                            Property_BDBID: row.id,
                            "System ID/Tag": system.tag_id,
                            System: systemField?.label,
                            "System Name": system.name,
                            "Component ID/Tag": component?.tag_id,
                            Component: componentName,
                            "Component Name": component?.name,
                            "Point Name": resolvePointName(point, pointsOptions, componentName),
                            "Point Tag/ID": item.points[point].tag_id,
                            "Exists in BAS": getColumnMap(item.points[point].point_exists_on_bas)?.label,
                            "Currently Trending": getColumnMap(item.points[point].point_currently_trending)?.label,
                            "Has Setpoint": getColumnMap(item.points[point].has_a_setpoint)?.label,
                            Note: item.points[point].notes,
                        };
                        totalResult.push(p);
                    });
                }
            });
        }
    });
    return totalResult;
};

/**
* buildCollection
* @param {any} data
* @param {string[]} keys
* @return {Record<string, number>}
* */
const buildCollection = (data:any, keys:string[]):Record<string, number> => Object.keys(data).filter((key) => keys.includes(key))
    .reduce((obj, key) => {
        const filtered:Record<string, number> = obj;
        filtered[key] = data[key];
        return filtered;
    }, {});

/**
* @param {WorkSheet} sheet
* @return {string[]}
* getSheetHeaders
* https://github.com/SheetJS/sheetjs/issues/214#issuecomment-1146229725
* */
const getSheetHeaders = (sheet:WorkSheet):string[] => {
    // eslint-disable-next-line prefer-regex-literals
    const headerRegex = new RegExp("^([A-Za-z]+)1='(.*)$");
    const cells:string[]= utils.sheet_to_formulae(sheet);
    return cells.filter((item) => headerRegex.test(item)).map((item) => item.split("='")[1]);
};

/**
* @param {WorkSheet} worksheet
* @return {WorkSheet}
* autoFitColumns
* */
const autoFitColumns = (worksheet:WorkSheet):WorkSheet => {
    const headers = getSheetHeaders(worksheet);
    const objectMaxLength:ColInfo[] = [];
    headers.forEach((header:any) => objectMaxLength.push({width: header.length}));
    // eslint-disable-next-line no-param-reassign
    worksheet["!cols"] = objectMaxLength;
    return worksheet;
};

/**
* addSheetToWorkbook
* @param {Workbook} wb
* @param {WorkSheet|null} sheet
* @param {string} title
* @return {void}
*/
const addSheetToWorkbook = (wb:WorkBook, sheet:WorkSheet|null, title:string):void => {
    if (sheet) {
        utils.book_append_sheet(wb, autoFitColumns(sheet), title);
    } else {
        // We don't want to throw an error, as we want the workbook to
        // still be created, and ignore said sheet.
        // console.log(`No data available to add a sheet for ${title}.`);
    }
};

/**
* buildSheets
* @param {SheetType[]} sheets
* @param {any} row
* @return {WorkBook}
*/
const buildWorkbook = (sheets:SheetType[], row:any):WorkBook => {
    const wb:WorkBook = utils.book_new();
    sheets.forEach((item:SheetType) => {
        let sheet;
        if (item.fields) sheet = convertJsonToExcelSheet(item.data, item.fields, row);
        else sheet = item.data.length? utils.json_to_sheet(item.data) : null;
        addSheetToWorkbook(wb, sheet, item.title);
    });
    return wb;
};

/**
* singlePropertyExport
* @param {any} row
* @param {any} payload
* @return {WorkBook}
*/
const constructSinglePropertyExport=(row:any, payload:AxiosResponse):WorkBook|null => {
    if (payload.status === 200) {
        try {
            const {data} = payload;
            const SHEETS:SheetType[] = [
                {data: [row], fields: PROPERTY_FIELDS, title: "Property"},
                {data: data.meterinfo, fields: REAL_TIME_METER_FIELDS, title: "Real-time Meters"},
                {data: data.propertydocument, fields: DOCUMENTS_FIELDS, title: "Documents"},
                {data: data.bas, fields: BAS_FIELDS, title: "Building Automation Systems"},
                {data: buildCollection(data, KEYS.heating.keys), fields: HEATING_FIELDS, title: "Heating"},
                {data: buildCollection(data, KEYS.cooling.keys), fields: COOLING_FIELDS, title: "Cooling"},
                {data: buildCollection(data, KEYS.airhandling.keys), fields: AIR_HANDLING_FIELDS, title: "Air Handling"},
                {data: buildCollection(data, KEYS.terminal.keys), fields: TERMINAL_UNITS_FIELDS, title: "Terminal Units"},
                {data: collectAndResolveComponents(data, COMPONENT_FIELDS, row), fields: null, title: "Components"},
                {data: collectAndResolvePoints(data, row), fields: null, title: "Points"},
            ];
            return buildWorkbook(SHEETS, row);
        } catch (e) {
            return null;
        }
    } else {
        return null;
    }
};

/**
 * constructFileXLSX
 * @param {Workbook} data
 * @param {string} fileName
 * @return {void}
 */
const constructFileXLSX = (data:WorkBook, fileName:string):void => {
    writeFileXLSX(data, fileName);
};

export {
    constructSinglePropertyExport,
    constructFileXLSX,
    convertJsonToExcelSheet,
    buildCollection,
    collectAndResolveComponents,
    collectAndResolvePoints,
};
