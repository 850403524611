import React, {useState, useEffect, Dispatch, SetStateAction, useCallback} from "react";
import {Box, Paper, Grid, Typography} from "@mui/material";
import {ControlPointDuplicate, ArchiveOutlined, HeatPumpOutlined, AddOutlined, CorporateFareOutlined, SmsFailedOutlined, UnarchiveOutlined, DeleteOutlined} from "@mui/icons-material";
import {GridRenderCellParams, GridColDef} from "@mui/x-data-grid";
import {useNavigate, useLocation} from "react-router-dom";
import {enqueueSnackbar} from "notistack";
import {AxiosResponse} from "axios";
import _ from "lodash";
import {FieldValues} from "react-hook-form";
import {APP_PATHS, PROPERTY_ID_EQUIPMENT, PROPERTY_CAMPUS_ID_EQUIPMENT, PROPERTY_ID_BAS, ARCHIVED_REASONS, BASE_ROUTE, PROPERTY_TYPES} from "../config";
import {TransformData, FilterChipType, ParentProps, SearchType, DialogType, EquipmentType} from "../types";
import {resolveFilterChipValues, transformEquipmentData, constructSystemArchiveConfig, constructSystemDeleteConfig} from "../handlers";
import {useContext} from "../components/generics/Context";
import useCall, {BaseState} from "../components/generics/useCall";
// components
import DataGrid from "../components/generics/DataGrid";
import {BaseSkeleton} from "../components/generics/DataGridUtils";
import Link from "../components/generics/Link";
import EmptyStateBlock from "../components/generics/EmptyStateBlock";
import {Button} from "../components/generics/inputs";
import {EquipmentFilter, EquipmentFields, Search as SearchFilter, validateSearch, AddEquipmentFilter, EQUIPMENTS, Archive, Prompt} from "../components/forms";

interface Props extends ParentProps{
    // NOTE: Empty For future expansion.
}

export const EQUIPMENT_COLUMNS_DEF:GridColDef[]=[
    {
        field: "name",
        headerName: "Name",
        flex: 1.5,
        renderCell: (params:GridRenderCellParams) => (
            <Link
                to={`${APP_PATHS.EQUIPMENT_DETAILS
                    .replace(/:propertyId/g, params.row.property_id)
                    .replace(/:equipmentId/g, params.row.id)
                    .replace(/:systemId/g, params.row.key)}`}
                state={{record: params.row}}
            >
                {params.value}
            </Link>
        ),
    },
    {field: "equipment", headerName: "Equipment", flex: 1},
    {field: "tag_id", headerName: "Tag/ID", flex: 0.5},
    {field: "plant", headerName: "Category", flex: 1},
    // BAS key does not exist it is concat of bas_software + bas_version
    {
        field: "bas",
        headerName: "BAS",
        flex: 1,
        renderCell: (params:GridRenderCellParams) => (
            <Link
                to={APP_PATHS.BAS_DETAILS
                    .replace(/:propertyId/g, params.row.property_id)
                    .replace(/:basId/g, params.row.bas_id)}
            >
                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item>{params.row?.bas_software}</Grid>
                    <Grid item>{`(${params.row?.bas_version})`}</Grid>
                </Grid>
            </Link>
        ),
    },
    {field: "type", headerName: "Type", flex: 1},
    {field: "capacity", headerName: "Capacity", flex: 1},
    {field: "fuel_type", headerName: "Fuel", flex: 1},
    {field: "vfd", headerName: "VFD?", flex: 0.5},
    {field: "piping_config", headerName: "Piping Configuration", flex: 1},
];

export interface State extends BaseState{
    search:SearchType|null
    filterStatus:boolean
    filterValues:any
    filterChipKey:any
    showArchived:boolean
    equipments:AxiosResponse|null
    bases:AxiosResponse|null
    row:AxiosResponse|null
}

/**
 * Equipment
 * @param {Props} props
 * @return {React.ReactElement}
 */
function Equipment(props:Props):React.ReactElement {
    const context=useContext();
    const [state, setState]:[State, Dispatch<SetStateAction<State>>]=useState<State>({
        search: null,
        filterStatus: false,
        filterValues: null,
        filterChipKey: null,
        showArchived: false,
        equipments: null,
        bases: null,
        dialog: "NONE",
        row: null,
        formValues: null,
        record: null,
    });
    const location:any=useLocation();
    const navigate=useNavigate();
    const {get, call}=useCall({state, setState: setState as any});

    const equipment:EquipmentType=EQUIPMENTS.find((e:EquipmentType) => e.key===state.row?.data.key && (state.row?.data?.variant?(state.row?.data?.variant===e?.variant):true)) as EquipmentType;

    /**
     * onDialogClick
     * @param {DialogType} type
     * @param {State} newState
     * @return {void}
     */
    const onDialogClick=(type:DialogType, newState?:State) => (args:React.MouseEvent):void => setState({...(newState||_.cloneDeep(state)), dialog: type});

    /**
     * resetState
     */
    const resetState=useCallback(() => {
        const stateClone=_.cloneDeep(state);
        stateClone.dialog="NONE";
        stateClone.formValues=null;
        stateClone.row=null;
        stateClone.record=null;
        stateClone.bases=null;
        stateClone.equipments=null;
        stateClone.search=null;
        stateClone.filterStatus=false;
        stateClone.filterValues=null;
        stateClone.filterChipKey=null;
        stateClone.showArchived=false;
        setState(stateClone);
    }, [state]);

    /**
     * onSystemArchiveSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onSystemArchiveSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${state.row?.data.name} equipment archived`, {variant: "success"});
        // trigger refresh cycle
        resetState();
        context.setViewState("REFRESH");
    }, [context, resetState, state.row?.data.name]);

    /**
     * onSystemRestoreSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onSystemRestoreSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${state.row?.data.name} equipment restored`, {variant: "success"});
        // trigger refresh cycle
        resetState();
        context.setViewState("REFRESH");
    }, [context, resetState, state.row?.data.name]);

    /**
     * onSystemDeleteSuccess
     * @param {AxiosResponse} res;
     * @return {void}
     */
    const onSystemDeleteSuccess=useCallback((res:AxiosResponse):void => {
        // trigger snack msg
        enqueueSnackbar(`${state.row?.data.name} equipment Deleted`, {variant: "success"});
        // trigger refresh cycle
        resetState();
        context.setViewState("REFRESH");
    }, [context, resetState, state.row?.data.name]);

    /**
     * onSystemArchiveSubmit
     * @param {FieldValues} values
     * @return {void}
     */
    const onSystemArchiveSubmit= async (values:FieldValues):Promise<void> => {
        try {
            call(constructSystemArchiveConfig(values, props.property, equipment, state.row as AxiosResponse, true), values, onSystemArchiveSuccess, "archive");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onSystemRestoreSubmit
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onSystemRestoreSubmit= async (args:React.MouseEvent):Promise<void> => {
        try {
            call(constructSystemArchiveConfig(null, props.property, equipment, state.row as AxiosResponse, false), null, onSystemRestoreSuccess, "restore");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onSystemDeleteSubmit
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onSystemDeleteSubmit= async (args:React.MouseEvent):Promise<void> => {
        try {
            call(constructSystemDeleteConfig(state.row as AxiosResponse, equipment), null, onSystemDeleteSuccess, "delete");
        } catch (e) {
            console.log(e);
        }
    };

    /**
     * onFilterFieldsChange
     * @param {string} fieldKey
     * @return {void}
     */
    const onFilterFieldsChange=(fieldKey:string) => (args:React.MouseEvent):void => {
        const stateClone=_.cloneDeep(state);
        stateClone.filterChipKey=fieldKey;
        // remove field key from form
        const newFilterValues=Object.keys(state.filterValues)
            .filter((key:string) => key!==fieldKey)
            .reduce(
                (obj:any, key:string) => {
                    obj[key]=state.filterValues[key]; // eslint-disable-line no-param-reassign
                    return obj;
                },
                {},
            );
        stateClone.filterValues=newFilterValues;
        setState(stateClone);
    };

    /**
     * onSubmit
     * @param {any} values
     * @return {void}
     */
    const onSubmit=(values:any):void => {
        const stateClone=_.cloneDeep(state);
        stateClone.filterValues=values;
        stateClone.filterStatus=false;
        stateClone.filterChipKey=null;
        setState(stateClone);
    };

    /**
     * onClose
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onClose=(args:React.MouseEvent):void => setState({..._.cloneDeep(state), filterStatus: false});

    /**
     * onSearchSubmit
     * @param {any} value
     * @return {Promise<void>}
     */
    const onSearchSubmit=async (value:any):Promise<void> => {
        if (await validateSearch(value.search)!==undefined) setState({..._.cloneDeep(state), search: {key: "name", value: value.search}});
        // if search input is empty and user clicks enter, reset search
        else setState({..._.cloneDeep(state), search: null});
    };

    /**
     * onSearchClear
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onSearchClear=(args:React.MouseEvent):void => setState({..._.cloneDeep(state), search: null});

    /**
     * onAddEquipmentClick
     * @param {DialogType} type
     * @return {void}
     */
    const onAddEquipmentClick=(type:DialogType) => (args:React.MouseEvent):void => setState({..._.cloneDeep(state), dialog: type});

    /**
     * onAddEquipmentSubmit
     * @param {EquipmentType} e
     * @return {void}
     */
    const onAddEquipmentSubmit= (e:EquipmentType) => (args:React.MouseEvent):void => (
        navigate(APP_PATHS.EQUIPMENT_POST
            .replace(/:propertyId/g, props.property.data.id)
            .replace(/:systemId/g, e.key), {state: {record: e, action: "new"}})
    );

    /**
     * transformData
     * @param {AxiosResponse} response
     * @param {GridColDef[]} colDef
     * @return {AxiosResponse}
     */
    const transformData=(response:AxiosResponse, colDef:GridColDef[]):AxiosResponse => {
        // concat arg to transformEquipmentData is set to false; response is coming from internal DataGrid
        const data:TransformData=transformEquipmentData(response, colDef, {filter: state.filterValues, isArchived: state.showArchived, search: state.search});
        return data.response as AxiosResponse;
    };

    // 412 handling
    useEffect(() => {
        if (context.viewState==="PENDING_END" && state.record && state.formValues) {
            if ("archive" in state.formValues) call(constructSystemArchiveConfig(state.formValues.archive, props.property, equipment, state.record, true), state.formValues.archive, onSystemArchiveSuccess, "archive");
            else if ("restore" in state.formValues) call(constructSystemArchiveConfig(null, props.property, equipment, state.record, false), null, onSystemRestoreSuccess, "restore");
            else if ("delete" in state.formValues) call(constructSystemDeleteConfig(state.record as AxiosResponse, equipment), null, onSystemDeleteSuccess, "delete");
        }
    }, [context.viewState, call, props.property, state.formValues, state.record, equipment, onSystemArchiveSuccess, onSystemRestoreSuccess, onSystemDeleteSuccess]);

    useEffect(() => {
        const propertyColDef:GridColDef|undefined=EQUIPMENT_COLUMNS_DEF.find((col:GridColDef) => col.field==="property");
        if (PROPERTY_TYPES.includes(props.property.data.recordtype)) {
            // remove property ColDef
            if (propertyColDef) EQUIPMENT_COLUMNS_DEF.splice(5, 1);
            // fetch building equipments
            if (state.equipments===null) get(`${PROPERTY_ID_EQUIPMENT.replace(/{id}/g, props.property.data.id)}`, "equipments");
        } else if (props.property.data.recordtype==="Campus") {
            if (propertyColDef===undefined) {
                // push property ColDef
                EQUIPMENT_COLUMNS_DEF.splice(5, 0, {
                    field: "property",
                    headerName: "Property",
                    flex: 1,
                    renderCell: (p:GridRenderCellParams) => (
                        <Link to={APP_PATHS.PROPERTY.replace(/:propertyId/g, p.row.property_id)}>{p.row._.name}</Link>
                    ),
                });
            }
            // fetch campus equipments
            if (state.equipments===null) get(`${PROPERTY_CAMPUS_ID_EQUIPMENT.replace(/{id}/g, props.property.data.id)}`, "equipments", true);
        }
        if ((props.property?.data.parent_property_id || props.property?.data.id) && state.bases===null) get(`${PROPERTY_ID_BAS.replace(/{id}/g, props.property.data.parent_property_id||props.property.data.id)}`, "bases", true);
        if (state.row?.data && state.record===null) get(`${BASE_ROUTE}${state.row.data.links.self}`, "record");
        if (state.record?.status===410) resetState();
    }, [resetState, state.row, state.record, props.property, get, state.equipments, state.bases, props.property.data.parent_property_id]);

    useEffect(() => {
        if (location.state?.isPostCanceled) {
            enqueueSnackbar("Add system canceled", {variant: "warning"});
            window.history.replaceState({}, "");
        }
    }, [location.state?.isPostCanceled]);

    const result:FilterChipType=resolveFilterChipValues(state.filterValues, EquipmentFields, state.filterChipKey, state.search, state.showArchived);
    // this is called to transform api response as CAMPUS vs BUILDING payload is different
    const totalEquipmentData:TransformData=transformEquipmentData(state.equipments, EQUIPMENT_COLUMNS_DEF, {concat: true, format: true});

    if (context.viewState==="LOADING") return <BaseSkeleton />;

    // resolve base no rows overlay
    let baseNoRowsOverlay;
    if (PROPERTY_TYPES.includes(props.property.data.recordtype)) {
        if (state.bases?.data.results.filter((i:any) => i.archived===false).length===0) {
            baseNoRowsOverlay=(
                <EmptyStateBlock
                    header="No equipment for this property."
                    subHeaders={["To add equipment, first add a BAS."]}
                    icon={HeatPumpOutlined}
                    primaryAction={{
                        onClick: (args:React.MouseEvent) => navigate(APP_PATHS.BAS_POST.replace(/:propertyId/g, props.property.data.id)),
                        label: "ADD BAS",
                        startIcon: <AddOutlined />,
                    }}
                />
            );
        } else {
            baseNoRowsOverlay=(
                <EmptyStateBlock
                    header="No equipment for this property."
                    subHeaders={["Continue by adding equipment.", "Each piece of equipment can be associated with a BAS."]}
                    icon={HeatPumpOutlined}
                    primaryAction={{
                        onClick: onAddEquipmentClick("EQUIPMENT_LIST"),
                        label: "ADD EQUIPMENT",
                        startIcon: <AddOutlined />,
                    }}
                />
            );
        }
    }

    // equipment cannot be created on campus level
    if (props.property.data.recordtype==="Campus" && totalEquipmentData.response?.data.total_count===0) {
        return (
            <Box sx={{marginTop: 8}}>
                <EmptyStateBlock
                    header="No equipment in campus properties."
                    subHeaders={["You can add equipment at any of the properties in this campus.", "Each piece of equipment can be associated with a BAS."]}
                    icon={HeatPumpOutlined}
                    primaryAction={{
                        onClick: (args:React.MouseEvent) => navigate(APP_PATHS.CAMPUS_PROPERTIES.replace(/:propertyId/g, props.property.data.id)),
                        label: "CAMPUS PROPERTIES",
                        startIcon: <CorporateFareOutlined />,
                    }}
                />
            </Box>
        );
    }

    return (
        <Box>
            {/* Add Equipment Dialog */}
            <AddEquipmentFilter
                status={state.dialog}
                dialogType="EQUIPMENT_LIST"
                onClose={onAddEquipmentClick("NONE")}
                onSubmit={onAddEquipmentSubmit}
                equipments={EQUIPMENTS}
            />
            {/* archive dialog */}
            {state.row?.data && (
                <Archive
                    status={state.dialog}
                    dialogType="EQUIPMENT_ARCHIVE"
                    onClose={onDialogClick("NONE")}
                    onSubmit={onSystemArchiveSubmit}
                    title={state.row?.data.name}
                    message={(
                        <Box>
                            <Typography variant="body1">
                                Any associated components will also be archived. Archived equipment will not be included in exports or reports for this property. BAS association is maintained.
                            </Typography>
                            <Typography variant="body1">Archived equipment can be restored.</Typography>
                        </Box>
                    )}
                    reasons={ARCHIVED_REASONS}
                />
            )}
            {/* restore dialog */}
            {state.row?.data && (
                <Prompt
                    status={state.dialog}
                    dialogType="EQUIPMENT_RESTORE"
                    onClose={onDialogClick("NONE")}
                    onSubmit={{onClick: onSystemRestoreSubmit, label: "Restore"}}
                    title={`Restore ${state.row?.data.name}`}
                    message={(<Typography variant="body1">Any associated components will remain archived and can be restored individually. Restored equipment will be included in exports or reports for this property.</Typography>)}
                />
            )}
            {/* delete dialog */}
            {state.row?.data && (
                <Prompt
                    status={state.dialog}
                    dialogType="EQUIPMENT_DELETE"
                    onClose={onDialogClick("NONE")}
                    onSubmit={{onClick: onSystemDeleteSubmit, label: "Delete"}}
                    title={`Delete ${state.row?.data.name}`}
                    message={(<Typography variant="body1">This action cannot be undone.</Typography>)}
                />
            )}
            {/* Table */}
            <DataGrid
                columns={EQUIPMENT_COLUMNS_DEF}
                rows={totalEquipmentData.response}
                transformData={transformData}
                filter={(
                    <Paper sx={{width: "600px", maxWidth: "600px"}}>
                        <EquipmentFilter
                            categories={totalEquipmentData.categories}
                            equipments={totalEquipmentData.equipments}
                            types={totalEquipmentData.types}
                            values={state.filterValues}
                            onSubmit={onSubmit}
                            onClose={onClose}
                        />
                    </Paper>
                )}
                search={<SearchFilter values={state.search?.value} onSubmit={onSearchSubmit} onClear={onSearchClear} helperText="Search for equipment in this property" />}
                filterFields={result.filterFields}
                onFilterFieldsChange={onFilterFieldsChange}
                state={state}
                setState={setState}
                getRowId={(row:any) => row.gridId}
                rowId="gridId"
                actions={
                    result.query.get("archived")==="true"
                        ?[{
                            label: "Restore",
                            key: "restore",
                            icon: <UnarchiveOutlined />,
                            onClick: (row) => (args:React.MouseEvent):void => {
                                onDialogClick("EQUIPMENT_RESTORE", {..._.cloneDeep(state), row: {data: row} as AxiosResponse})(args);
                            },
                        },
                        {
                            label: "Delete",
                            key: "delete",
                            color: "warning",
                            icon: <DeleteOutlined />,
                            onClick: (row) => (args:React.MouseEvent):void => {
                                onDialogClick("EQUIPMENT_DELETE", {..._.cloneDeep(state), row: {data: row} as AxiosResponse})(args);
                            },
                        }]
                        :[{
                            label: "Duplicate",
                            key: "duplicate",
                            icon: <ControlPointDuplicate />,
                            onClick: (row) => (args:React.MouseEvent):void => {
                                enqueueSnackbar("System duplicated", {variant: "success"});
                                navigate(APP_PATHS.EQUIPMENT_POST
                                    .replace(/:propertyId/g, props.property.data.id)
                                    .replace(/:systemId/g, row.key), {state: {record: row, action: "duplicate"}});
                            },
                        },
                        {
                            label: "Archive",
                            key: "archive",
                            icon: <ArchiveOutlined />,
                            onClick: (row) => (args:React.MouseEvent):void => {
                                onDialogClick("EQUIPMENT_ARCHIVE", {..._.cloneDeep(state), row: {data: row} as AxiosResponse})(args);
                            },
                        }]
                }
                isQuery={result.isQuery}
                baseNoRowsOverlay={baseNoRowsOverlay}
                noRowsOverlay={(
                    <EmptyStateBlock
                        header="No equipment match search criteria."
                        subHeaders={["Try a different search term or filter."]}
                        icon={SmsFailedOutlined}
                    />
                )}
                primaryAction={PROPERTY_TYPES.includes(props.property.data.recordtype)
                    ?<Button sx={{width: "auto"}} label="Add Equipment" onClick={(onAddEquipmentClick("EQUIPMENT_LIST"))} variant="contained" size="large" startIcon={<AddOutlined />} />
                    :undefined}
            />
        </Box>
    );
}

export default Equipment;
