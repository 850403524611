import React, {useState, useEffect, Dispatch, SetStateAction} from "react";
import {Box, Paper} from "@mui/material";
import {FileDownloadOutlined, SmsFailedOutlined, CorporateFareOutlined} from "@mui/icons-material";
import {GridRenderCellParams, GridColDef} from "@mui/x-data-grid";
import {useParams} from "react-router-dom";
import {AxiosResponse} from "axios";
import _ from "lodash";
import {Auth0ContextInterface, useAuth0, User} from "@auth0/auth0-react";
import {APP_PATHS, PROPERTY_CAMPUS_ID_PROPERTIES, PRIMARY_FUNCTION_ROUTE} from "../config";
import {resolveFilterChipValues} from "../handlers";
import {ParentProps, FilterChipType, SearchType} from "../types";
import useCall, {BaseState} from "../components/generics/useCall";
// components
import DataGrid from "../components/generics/DataGrid";
import Link from "../components/generics/Link";
import EmptyStateBlock from "../components/generics/EmptyStateBlock";
import {PropertiesFilter, PropertiesFields, Search as SearchFilter, validateSearch} from "../components/forms";
import {onPropertyExport} from "./Properties";

interface Props extends ParentProps{
    // NOTE: Empty For future expansion.
}

const CAMPUS_PROPERTY_COLUMNS_DEF:GridColDef[]=[
    {
        field: "name",
        headerName: "Property Name",
        flex: 1.5,
        renderCell: (params:GridRenderCellParams) => <Link to={APP_PATHS.PROPERTY.replace(/:propertyId/g, params.row.id)}>{params.value}</Link>,
    },
    {field: "address", headerName: "Complete Address", flex: 1},
    {field: "id", headerName: "BDBID"},
    {field: "organization", headerName: "Agency", flex: 1},
    {field: "recordtype", headerName: "Record Type", flex: 1},
    {field: "primaryfunction", headerName: "Primary Function", flex: 1},
    {field: "building_size_sqft", headerName: "Gross Floor Area (sf)", flex: 1},
    {field: "year_built", headerName: "Year Built"},

];

export interface State extends Omit<BaseState, "dialog"|"record"|"formValues">{
    search:SearchType|null
    filterStatus:boolean
    filterValues:any
    filterChipKey:any
    primaryfunction:AxiosResponse|null
}

/**
 * CampusProperties
 * @param {Props} props
 * @return {React.ReactElement}
 */
function CampusProperties(props:Props):React.ReactElement {
    const params:any=useParams();
    const [state, setState]:[State, Dispatch<SetStateAction<State>>]=useState<State>({
        search: null,
        filterStatus: false,
        filterValues: null,
        filterChipKey: null,
        primaryfunction: null,
    });
    const {get}=useCall({state, setState: setState as any});
    const {user}:Auth0ContextInterface<User>= useAuth0();

    /**
     * onFilterFieldsChange
     * @param {string} fieldKey
     * @return {void}
     */
    const onFilterFieldsChange=(fieldKey:string) => (args:React.MouseEvent):void => {
        const stateClone=_.cloneDeep(state);
        stateClone.filterChipKey=fieldKey;
        // remove field key from form
        const newFilterValues=Object.keys(state.filterValues)
            .filter((key:string) => key!==fieldKey)
            .reduce(
                (obj:any, key:string) => {
                    obj[key]=state.filterValues[key]; // eslint-disable-line no-param-reassign
                    return obj;
                },
                {},
            );
        stateClone.filterValues=newFilterValues;
        setState(stateClone);
    };

    /**
     * onSubmit
     * @param {any} values
     * @return {void}
     */
    const onSubmit=(values:any):void => {
        const stateClone=_.cloneDeep(state);
        stateClone.filterValues=values;
        stateClone.filterStatus=false;
        stateClone.filterChipKey=null;
        setState(stateClone);
    };

    /**
     * onClose
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onClose=(args:React.MouseEvent):void => setState({..._.cloneDeep(state), filterStatus: false});

    /**
     * onSearchSubmit
     * @param {any} value
     * @return {Promise<void>}
     */
    const onSearchSubmit=async (value:any):Promise<void> => {
        if (await validateSearch(value.search)!==undefined) setState({..._.cloneDeep(state), search: {key: "name__contains", value: value.search}});
    };

    /**
     * onSearchClear
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onSearchClear=(args:React.MouseEvent):void => setState({..._.cloneDeep(state), search: null});

    useEffect(() => {
        if (state.primaryfunction===null) get(PRIMARY_FUNCTION_ROUTE, "primaryfunction");
    }, [get, state.primaryfunction]);

    const result:FilterChipType=resolveFilterChipValues(state.filterValues, PropertiesFields, state.filterChipKey, state.search, null);

    return (
        <Box>
            {/* Table */}
            <DataGrid
                columns={CAMPUS_PROPERTY_COLUMNS_DEF}
                url={`${PROPERTY_CAMPUS_ID_PROPERTIES.replace(/{id}/g, params.propertyId)}${result.isQuery?`?${result.query.toString()}`:""}`}
                filter={(
                    <Paper sx={{maxWidth: "350px"}}>
                        <PropertiesFilter
                            primaryfunctions={state.primaryfunction?.status===200?state.primaryfunction?.data?.results?.map((item:any) => item.name):[]}
                            values={state.filterValues}
                            onSubmit={onSubmit}
                            onClose={onClose}
                        />
                    </Paper>
                )}
                search={<SearchFilter values={state.search?.value} onSubmit={onSearchSubmit} onClear={onSearchClear} helperText="Search for Properties in this campus" />}
                filterFields={result.filterFields}
                onFilterFieldsChange={onFilterFieldsChange}
                state={state}
                setState={setState}
                actions={[
                    {
                        label: "Download",
                        key: "export",
                        icon: <FileDownloadOutlined />,
                        onClick: (row) => (args:React.MouseEvent):void => {
                            onPropertyExport(row, get, user as User);
                        },
                    },
                ]}
                isQuery={result.isQuery}
                baseNoRowsOverlay={(
                    <EmptyStateBlock
                        header="No properties in this campus"
                        subHeaders={["Properties for this campus have not been added to BASAT yet."]}
                        icon={CorporateFareOutlined}
                    />
                )}
                noRowsOverlay={(
                    <EmptyStateBlock
                        header="No properties match search criteria."
                        subHeaders={["You can search by property name or BDBID.", "You can use filters to narrow down results."]}
                        icon={SmsFailedOutlined}
                    />
                )}
            />
        </Box>
    );
}

export default CampusProperties;
