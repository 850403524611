import {SetStateAction, useCallback, Dispatch} from "react";
import {AxiosError, AxiosResponse, AxiosRequestConfig} from "axios";
import {FieldValues} from "react-hook-form";
import {Auth0ContextInterface, User, useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import {enqueueSnackbar} from "notistack";
import _ from "lodash";
import api, {get} from "../../api";
import {STATUS_400_RANGE, CALL_DELAY, CALL_DELAY_INCREMENT, STATUS_500_RANGE} from "../../config";
import {useContext} from "./Context";
import {DialogType} from "../../types";
import {State as BasState} from "../../views/Bas";
import {State as BasDetailsState} from "../../views/BasDetails";
import {State as ChangeLogState} from "../layout/ChangeLog";
import {State as CampusState} from "../../views/Campus";
import {State as CampusProperties} from "../../views/CampusProperties";
import {State as EquipmentState} from "../../views/Equipment";
import {State as PropertiesState} from "../../views/Properties";
import {State as PropertyState} from "../../views/Property";
import {State as EquipmentDetailsState, ComponentState} from "../../views/EquipmentDetails";
import slackCall from "./SlackCall";

export interface BaseState{
    dialog:DialogType
    record:AxiosResponse|null
    formValues:FieldValues|null
}

interface Props{
    state?:BasState|BasDetailsState|ChangeLogState|CampusState|CampusProperties|EquipmentState|EquipmentDetailsState|PropertiesState|PropertyState|ComponentState
    setState?:Dispatch<SetStateAction<BasState|BasDetailsState|ChangeLogState|CampusState|CampusProperties|EquipmentState|EquipmentDetailsState|PropertiesState|PropertyState|ComponentState>>
    redirectUrl?:string
}

// NOTE: https://github.com/cunybpl/basatdb/issues/157#issuecomment-2043200232
const STATUS_409_ERROR_MESSAGES:any={
    DUPLICATE: "Duplicate Record",
    MISSING: "Missing Record",
    MISMATCHED: "Mismatching Record",
    UNDETERMINED: "UNDETERMINED",
    DELETED: "Record Deleted",
};

type CallType="archive"|"edit"|"restore"|"post"|"delete";

/**
 * useCall
 * @return {Props}
 */
const useCall = (props:Props):any => {
    const context:any=useContext();
    const {user}:Auth0ContextInterface<User>= useAuth0();
    const navigate=useNavigate();
    /**
     * onError
     * @param {AxiosError} err
     * @param {FieldValues|null} values
     * @return {void}
     */
    const onError=useCallback(async (err:AxiosError, values:FieldValues|null, callType:CallType):Promise<void> => {
        const {status, data}=err.response as AxiosResponse;
        // pre-condition failed (etag)
        if (status===412) {
            enqueueSnackbar("Record has been updated. Refetching...", {variant: "info"});
        } else if (status===409 && "detail" in data) {
            enqueueSnackbar(`${STATUS_409_ERROR_MESSAGES[data.detail.key]}`, {variant: "error"});
        } else if (status===410) {
            enqueueSnackbar("Record has been deleted. Redirecting...", {variant: "info"});
            if (props.redirectUrl) navigate(props.redirectUrl);
        } else if (STATUS_400_RANGE.includes(status)) {
            await slackCall(err.response as AxiosResponse, user as User);
            enqueueSnackbar("Failed Saving!", {variant: "error"});
        } else if (err.response && err.response.status in STATUS_500_RANGE) await slackCall(err.response as AxiosResponse, user as User);

        const stateClone:any=_.cloneDeep(props.state);
        // trigger refresh cycle
        if (stateClone && "documents" in stateClone) stateClone.documents=null;
        if (stateClone && "meterinfo" in stateClone) stateClone.meterinfo=null;
        if (stateClone && "record" in stateClone) stateClone.record=null;
        if (status===412) {
            if (stateClone && "formValues" in stateClone) stateClone.formValues={[callType]: values};
            context.setViewState("PENDING_BEGIN");
            if (stateClone && "viewMode" in stateClone) stateClone.viewMode="EDIT_MODE";
        } else if (status===409) {
            context.setViewState("REFRESH");
            if (props.redirectUrl && stateClone && "component" in stateClone) navigate(props.redirectUrl);
        } else {
            context.setViewState("REFRESH");
            if (stateClone && "viewMode" in stateClone) stateClone.viewMode="VIEW_MODE";
        }
        if (props.setState) props.setState(stateClone);
    }, [context, props, navigate, user]);

    /**
     * call
     * https://github.com/axios/axios#concurrency-deprecated
     * @param {AxiosRequestConfig|AxiosRequestConfig[]|undefined} config
     * @param {FieldValues|null} values
     * @param {any} onSuccess
     * @return {Promise<void>}
     */
    const call=useCallback(async (config:AxiosRequestConfig|AxiosRequestConfig[]|undefined, values:FieldValues|null, onSuccess:any, callType:CallType):Promise<void> => {
        if (config===undefined) return;
        context.setViewState("LOADING");
        if (Array.isArray(config)) {
            let delay=CALL_DELAY;
            const promises:Promise<AxiosResponse>[]=config.map((c:AxiosRequestConfig, i:number) => {
                if (i!==0) delay+=CALL_DELAY_INCREMENT;
                return (
                    new Promise((resolve:any, reject:any) => { _.delay(resolve, delay); })
                        .then(() => api(c))
                );
            });
            Promise.all(promises)
                .then((res:AxiosResponse[]) => onSuccess(res))
                .catch(async (error:AxiosError) => {
                    if (error.response) onError(error, values, callType);
                });
        } else {
            api(config)
                .then((res:AxiosResponse) => onSuccess(res))
                .catch(async (error:AxiosError) => {
                    if (error.response) onError(error, values, callType);
                });
        }
    }, [onError, context]);

    /**
     * resolveGetCall
     * @return {Promise<AxiosResponse|undefined>}
     */
    const resolveGetCall=useCallback(async (url:string, key:string, all=false, isDelay=true):Promise<AxiosResponse|undefined> => {
        if (!context.token) return undefined;

        /**
         * helper
         * @param {string} _url
         * @return {Promise<AxiosResponse|undefined>}
         */
        const helper=async (_url:string):Promise<AxiosResponse|undefined> => {
            let response:AxiosResponse|undefined;
            if (all) response=await get(_url);
            else response=await api.get(_url).then((res:AxiosResponse) => res).catch((err:AxiosError) => err.response);
            if (response?.status===410) {
                enqueueSnackbar("Record has been deleted.", {variant: "info"});
            } else if (STATUS_400_RANGE.includes(response?.status as number)) {
                enqueueSnackbar("Failed Fetching!", {variant: "error"});
                await slackCall(response as AxiosResponse, user as User);
            } else if (response && response.status in STATUS_500_RANGE) await slackCall(response as AxiosResponse, user as User);
            if (props.state && props.setState) props.setState({..._.cloneDeep(props.state), [key]: response});
            return response;
        };

        if (isDelay) {
            _.delay(helper, CALL_DELAY, [url]);
            return undefined;
        }
        return helper(url);
    }, [context, props, user]);

    return {call, get: resolveGetCall};
};

export default useCall;
